import SEARCH_STATUS from '../constants/SearchStatus';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UrlHelper from '../utilities/UrlHelper';

export default class ResultMessage extends Component {
  getUnavailableText(message) {
    const { domainName } = this.props;
    const htmlForMessage = '<span class="unavailable">' + domainName + '</span>';
    const domainResultMessage = message.replace('{0}', htmlForMessage);
    return <h4 className="headline-primary" dangerouslySetInnerHTML={{ __html: domainResultMessage }} />;
  }

  getRestrictedText(message) {
    const { language, showRestrictedAsUnavailable, plid, urlOverride } = this.props;
    if (showRestrictedAsUnavailable) {
      return this.getUnavailableText(language.sorryDomainUnavailable);
    }

    const { domainName } = this.props;
    const domainResultMessage = this.getUnavailableText(message);
    const serpLink = UrlHelper.getSerpUrl(domainName, plid, urlOverride);
    const regularSearchMessage = <span><a href={serpLink}>{language.restrictedNamesHelpText}</a></span>;
    return (
      <div>
        {domainResultMessage}
        {regularSearchMessage}
      </div>
    );
  }

  getResultsMessage() {
    const { language, searchStatus } = this.props;
    switch (searchStatus) {
      case SEARCH_STATUS.None:
        return <h4 className="headline-primary text-muted initial-msg">{language.initialText}</h4>;
      case SEARCH_STATUS.Available:
        return <h4 className="headline-primary">{language.domainIsAvailable}</h4>;
      case SEARCH_STATUS.Unavailable:
        return this.getUnavailableText(language.sorryDomainUnavailable);
      case SEARCH_STATUS.Taken:
        return this.getUnavailableText(language.sorryDomainTaken);
      case SEARCH_STATUS.PreReg:
        return this.getRestrictedText(language.preRegText);
      case SEARCH_STATUS.Auction:
        return this.getRestrictedText(language.auctionText);
      case SEARCH_STATUS.Restricted:
        return this.getRestrictedText(language.restrictedTldText);
      case SEARCH_STATUS.Error:
        return <h4 className="headline-primary">{language.domainSearchError}</h4>;
      default:
    }
  }

  render() {
    const { showResultsMessage } = this.props;
    if (showResultsMessage === false) {
      return null;
    }

    return (
      <div id="searchResults" className="default-msg">
        {this.getResultsMessage()}
      </div>
    );
  }
}

ResultMessage.propTypes = {
  domainName: PropTypes.string.isRequired,
  language: PropTypes.object.isRequired,
  plid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  searchStatus: PropTypes.string.isRequired,
  showResultsMessage: PropTypes.bool,
  showRestrictedAsUnavailable: PropTypes.bool,
  urlOverride: PropTypes.string
};
