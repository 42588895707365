import { data } from '@ux/data';

export default (item) => ({
  app: data?.app,
  currency: data?.currency,
  duration: item?.period,
  durationUnit: item?.periodUnit,
  isRenewal: item?.isRenewal,
  itemTrackingCode: item?.tracking?.itemTrackingCode,
  languageName: data?.languageName,
  market: data?.market,
  plid: data?.privateLabelId,
  pfid: item?.pfid,
  packageId: item?.packageId,
  productTypeId: item?.productTypeId,
  quantity: item?.quantity,
  subtotal: item?.pricing?.subtotal,
  savings: item?.pricing?.savings
});
