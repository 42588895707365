import freeAllowedProductIds from './freeAllowedProductIds.json';

const getCartItems = (cart) => {
  const items = (cart?.items && [...cart?.items]) || [];
  cart?.groups?.map(group => group.items && items.push(...group.items));
  return items;
};

const getFreeDomainsCount = (items) => {
  return items.filter(item => item.productTypeId === 2 && item.pricing?.subtotal === 0).length;
};

const getFreeAllowedProductsCount = (items) => {
  return items.filter(item => ~freeAllowedProductIds.indexOf(item.pfid)).length;
};

export const isFreeSearchAllowed = (cart, privateLabelId) => {
  let isAllowed = false;
  if (privateLabelId === 1) {
    const items = getCartItems(cart);
    isAllowed = getFreeDomainsCount(items) < getFreeAllowedProductsCount(items);
  }
  return isAllowed;
};
