import defaultAttributes from './attributes';
import config, { ConfigConstants } from '@px/config';
import { cookie } from '@ux/util';
import HivemindClient, { hivemindConstants } from '@godaddy/hivemind-sdk-browser';

let _hivemindClient;
function getHivemindClient(usePreload) {
  if (_hivemindClient) {
    return _hivemindClient;
  }

  const visitorCookie = cookie.getItem('visitor');
  const visitor = visitorCookie ? visitorCookie.split('=')[1] : null;
  const sdkKey = config.get(ConfigConstants.SPLIT_IO_BROWSER);

  _hivemindClient = new HivemindClient({
    app: 'px',
    usePreload,
    splitioOptions: {
      core: {
        authorizationKey: sdkKey
      }
    },
    subject: {
      [hivemindConstants.idTypes.VISITOR_GUID]: visitor
    }
  });

  return _hivemindClient;
}

function getTreatment(name, attributes = {}, timeout = 5000, splitType = 'visitor') {
  let experimentCookie = cookie.getItem(name);
  if (experimentCookie) {
    return Promise.resolve(experimentCookie);
  }

  const timeoutPromise = new Promise((resolve, reject) => {
    setTimeout(() => reject(), timeout)
  })

  const experimentPromise = new Promise((resolve) => {
    global._expDataLayer = global._expDataLayer || [];
    _expDataLayer.push({
      schema: 'get_variant_for_experiment',
      version: 'v2',
      data: {
        experiment_id: name,
        traffic_type: splitType,
        attributes: { ...defaultAttributes, ...attributes },
        callback: (treatment) => {
          treatment && resolve(treatment);
        }
      }
    })
  })

  return Promise.race([experimentPromise, timeoutPromise]);
}

function trackEvent(eventName, eventValue) {
  global._expDataLayer = global._expDataLayer || [];
  _expDataLayer.push({
    schema: 'add_event',
    version: 'v1',
    data: {
      eid: eventName,
      type: eventValue || 'impression'
    }
  });
}

const getHivemindTreatment = async (splitId, attributes = {}, usePreload = true) =>  {
  const experimentCookie = cookie.getItem(splitId);
  let result;

  if (experimentCookie) {
    result = experimentCookie;
  } else {
    const client = getHivemindClient(usePreload);
    await client.ready;
    const response = await client.getCohorts({
      experiments: [splitId],
      attributes: { ...defaultAttributes, ...attributes }
    });

    result = response && response.cohorts && response.cohorts[splitId] && response.cohorts[splitId].cohortId;
  }

  return result;
}

const getCohorts = async ({ labels, experiments, attributes, usePreload = true }) =>  {
  const cohortsCookie = cookie.getItem('px_cohorts');

  if (cohortsCookie) {
    return cohortsCookie;
  }

  const client = getHivemindClient(usePreload);
  await client.ready;
  const response = await client.getCohorts({
    experiments: experiments,
    labels: labels,
    attributes: { ...defaultAttributes, ...attributes }
  });

  return response?.cohorts;
}

export { getHivemindTreatment, getTreatment, getCohorts, trackEvent };