import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@ux/tooltip';
import HelpIcon from '@ux/icon/help';

export const RadioPlan = ({ currentPriceDisplay, listPriceDisplay, customPriceText, pricePerText, pricePerSecondaryText, productName, description, tooltipLinkText, features, eidPrefix, planKey }) => {

  let tooltip = null;
  if (features) {

    if (typeof (features[0]) === 'object') {
      features = Object.values(features[0]);
    }

    const tooltipContent = (<ul>
      {features.map((item, index) => {
        return item ? <li key={'item' + index} dangerouslySetInnerHTML={{ __html: item }} /> : null;
      })}
    </ul>);

    if (tooltipLinkText) {
      tooltip = (
        <div>
          <span className="view-details text-primary">
            <Tooltip
              message={tooltipContent}
              data-eid={`${eidPrefix}.${planKey}-view-details.click`}>
              <span>{tooltipLinkText}<span className="tooltip-help-icon"><HelpIcon weight={16} height={16} /></span>
              </span>
            </Tooltip>
          </span>
        </div>
      );
    } else {
      tooltip = (
        <div className="features-list-text">
          {tooltipContent}
        </div>
      );
    }
  }

  let customPriceTextBlock = null
  if (customPriceText) {
    customPriceTextBlock = (
      <div className="addon-pricing">
        <div className="pricing-block">
          <div className="text-primary-o font-primary-bold">
            {customPriceText}
          </div>
        </div>
      </div>
    );
  }


  let pricingBlock = null;
  if (currentPriceDisplay) {
    let crossedOutPrice = null;
    if (currentPriceDisplay !== listPriceDisplay) {
      crossedOutPrice = (
        <span className="list-price"><s>{listPriceDisplay}</s></span>
      );
    }
    if (pricePerSecondaryText) {
      crossedOutPrice = (
        <span className="secondary-list-price">{pricePerSecondaryText}</span>
      );
    }

    pricingBlock = (
      <div className="addon-pricing">
        <div className="pricing-block">
          <div className="text-primary-o font-primary-bold">
            <span className="display-price">{currentPriceDisplay}</span>{pricePerText}
          </div>
          {crossedOutPrice}
        </div>
      </div>
    );
  }

  return (
    <div className="price-wrapper">
      <div className="addon-details">
        <h3 className="font-primary-bold">{productName}</h3>
        {description}
        {tooltip}
      </div>
      {pricingBlock}
      {customPriceTextBlock}
    </div>
  );
};

RadioPlan.propTypes = {
  productName: PropTypes.string,
  description: PropTypes.string,
  customPriceText: PropTypes.string,
  currentPriceDisplay: PropTypes.string,
  listPriceDisplay: PropTypes.string,
  pricePerText: PropTypes.string,
  pricePerSecondaryText: PropTypes.string,
  tooltipLinkText: PropTypes.string,
  features: PropTypes.array,
  eidPrefix: PropTypes.string,
  planKey: PropTypes.string
};

export default RadioPlan;
