import language from '../../../utils/language';

export default (item, rules = {}) => {
  let label = item.label;
  if (item.productTypeId === 2 && item?.metadata?.domains) {
    label = item.metadata.domains[0].domain;
  }

  if (rules.label === 'DOMAIN_NAME' && item?.metadata?.domains) {
    label = item.metadata.domains[0].domain;
  } else if (rules.label === 'PRODUCT_NAME') {
    label = item.label;
  } else if (rules.label) {
    label = language(`ITEM_LABEL_${rules.label}`, null, rules.label);
  }

  return label;
};
