import { Currency } from '@godaddy/intl-formatter';

export function getDisplayPrice(microunits, market, currency) {
  if (isNaN(microunits) && typeof microunits !== "undefined") {
    return microunits;
  } else if (microunits >= 0 && market && currency) {
    const curr = new Currency();
    return curr.formatMicrounits(microunits, market, currency);
  }
  return null;
}

export function getPriceFromPlan(plan, priceType, market, currency) {
  if (plan.currentPriceDisplay || plan.listPriceDisplay) {
    return priceType === "list" ? plan.listPriceDisplay : plan.currentPriceDisplay;
  } else if (plan.pricing) {
    return getDisplayPrice(plan.pricing[priceType], market, currency);
  }
  return '';
}

export function getPricing(products, tld, tierId) {
  for (let i = products.length; i--;) {
    if (products[i].Tld === tld && products[i].TierId === tierId) {
      return products[i].PriceInfo;
    }
  }
}
