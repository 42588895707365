import createStyled from '../createStyled';
import spacing from '../styles/spacing';
import card from '../styles/card';
import border from '../styles/border';
import visibility from '../styles/visibility';
import background from '../styles/background';
import sizing from '../styles/sizing';

const Card = createStyled('div', 'Card')(
  card,
  spacing,
  sizing,
  background,
  visibility,
  border
);

export default Card;
