import React from 'react';
import PropTypes from 'prop-types';
import SearchComponent from './search-component';
import ResultMessage from './result-message';

const BaseComponent = (props) => {
  const {domainName, domainSearchProps, eid, language, showResultsMessage, showRestrictedAsUnavailable, searchStatus, showSearch, urlOverride, plid } = props;
  return (
    <div className="dpp-search">
      { showSearch && <h3 className="headline-primary label">{language.findYourDomain}</h3> }
      { showSearch && <SearchComponent domainSearchProps={domainSearchProps}
        eid={eid}
        onKeyPress={ props.onKeyPress }
        onSearchClick={ props.onSearchClick }
        searchStatus={searchStatus} /> }

      <ResultMessage
        domainName={domainName}
        plid={plid}
        showResultsMessage={showResultsMessage}
        showRestrictedAsUnavailable={showRestrictedAsUnavailable}
        language={language}
        searchStatus={searchStatus}
        urlOverride={urlOverride} />
    </div>
  );
};


BaseComponent.propTypes = {
  domainName: PropTypes.string.isRequired,
  domainSearchProps: PropTypes.object,
  placeholderText: PropTypes.string,
  plid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  eid: PropTypes.string,
  language: PropTypes.object.isRequired,
  searchTerm: PropTypes.string,
  showResultsMessage: PropTypes.bool,
  showRestrictedAsUnavailable: PropTypes.bool,
  onKeyPress: PropTypes.func,
  onSearchClick: PropTypes.func,
  searchStatus: PropTypes.string.isRequired,
  showSearch: PropTypes.bool,
  urlOverride: PropTypes.string
};

BaseComponent.defaultProps = {
  showSearch: true
}

export default BaseComponent;
