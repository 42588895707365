import language from '../../../utils/language';

export default (item, rules = {}) => {
  let annotation = '';
  if (rules.annotate) {
    annotation = item.pricing.subtotal === 0 && item.canRemoveAlone
      ? language('BANNER_FREE_WITH_PLAN')
      : language('BANNER_INCLUDED');
  }
  return annotation;
};
