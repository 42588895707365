import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UrlHelper from '../utilities/UrlHelper';

export class VirtualCartFormPost extends Component {
  constructor(props) {
    super(props);

    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
    this.submitForm(this.props);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.shouldSubmitForm !== this.props.shouldSubmitForm) {
      this.submitForm(this.props);
    }
  }

  submitForm(props) {
    if (props.shouldSubmitForm) {
      document.getElementById('virtualPostForm').submit();
    }
  }

  render() {
    const { payload, urlOverride, plid } = this.props;
    const payloadString = encodeURIComponent(JSON.stringify(payload));
    const url = UrlHelper.getVirtualCartUrl(urlOverride, plid);

    return (
      <form method="post" action={ url } id="virtualPostForm">
        <input type="hidden" name="postData" value={ payloadString } />
      </form>
    );
  }
}

VirtualCartFormPost.propTypes = {
  payload: PropTypes.object,
  plid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  shouldSubmitForm: PropTypes.bool,
  urlOverride: PropTypes.string
};

export default VirtualCartFormPost;
