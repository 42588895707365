import React from 'react';
import PropTypes from 'prop-types';

export const Card = (props) => {
  return (
    <div className="card ux-card">
      <div className="card-block">
        {props.children}
      </div>
    </div>
  );
};

Card.propTypes = {
  children: PropTypes.object
};

export default Card;
