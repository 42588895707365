/* eslint-disable max-statements */
import language from '../../../utils/language';
import money from '../../../utils/money';
import { calculateDuration, calculatePrice, calculateSavings } from '../../../utils/calculations';

export default (item, rules) => {
  const duration = {
    label: getLabel(item.period, item.periodUnit, rules.durationUnit),
    description: getDescription(rules.durationUnit),
    value: calculateDuration(item.period, item.periodUnit, rules.durationUnit)
  };

  if (item.periodOptions) {
    const options = [];
    item.periodOptions.forEach(periodOption => {
      const calculatedDuration = calculateDuration(periodOption.period, item.periodUnit, rules.durationUnit);
      if (!rules.durationOptions || rules.durationOptions.includes(calculatedDuration) || periodOption.period === item.period) {
        options.push(toDurationOption(item, rules, periodOption));
      }
    });

    if (options.length > 0) {
      duration.options = options;
    }
  }

  return duration;
};

function toDurationOption(item, rules, periodOption) {
  const label = getLabel(periodOption.period, item.periodUnit, rules.durationUnit);
  const value = calculateDuration(periodOption.period, item.periodUnit, rules.durationUnit);
  const selected = item.period === periodOption.period;
  const metadata = {};
  const pricing = {};

  if (periodOption.pfid) {
    metadata.pfid = periodOption.pfid;
  }

  if (periodOption.packageId) {
    metadata.durationUnit = rules.durationUnit;
    metadata.packageId = periodOption.packageId;
  }

  if (periodOption.pricing && rules.durationPricing) {
    pricing.price = money(periodOption.pricing.subtotal);
    pricing.unitPrice = getUnitPrice(periodOption.pricing.subtotal / periodOption.period, item.periodUnit, rules.durationUnit);

    if (periodOption.pricing.subtotal !== periodOption.pricing.list) {
      pricing.listPrice = money(periodOption.pricing.list);
      pricing.unitListPrice = getUnitPrice(periodOption.pricing.list / periodOption.period, item.periodUnit, rules.durationUnit);
      pricing.savings = getSavings(periodOption.pricing.subtotal, periodOption.pricing.list, rules.savings);
    }
  } else if (item.pricing && selected) {
    pricing.savings = getSavings(item.pricing.subtotal, item.pricing.list, rules.savings);
  }

  return { label, value, selected, ...pricing, metadata };
}

function getLabel(currentTerm, fromUnit, toUnit) {
  if (fromUnit === 'FREEMIUM') {
    return '';
  }
  const duration = calculateDuration(currentTerm, fromUnit, toUnit);
  return language(`DURATION_LABEL_${toUnit}`, { duration });
}

function getDescription(unit) {
  return language(`DURATION_DESCRIPTION_${unit}`);
}

function getUnitPrice(microunits, fromUnit, toUnit) {
  const price = calculatePrice(microunits, fromUnit, toUnit);
  return language(`PRICE_UNIT_${toUnit}`, { price: money(price) });
}

function getSavings(price, listPrice, type) {
  let savings;

  switch (type) {
    case 'PERCENT_OFF':
    case 'PERCENT_OFF_SALE':
      savings = calculateSavings(price, listPrice, 'PERCENT');
      break;
    case 'AMOUNT_OFF_SAVE':
      savings = money(calculateSavings(price, listPrice, 'AMOUNT'));
      break;
    default:
      savings = null;
      break;
  }

  return savings ? language(`SAVINGS_${type}`, { savings }) : '';
}
