import { useContext, useState, useEffect } from 'react';
import render from './index';
import { RenderContext } from './renderContext';

function useRender(itemOrGroup) {
  const { basket = null, showPrivacy = false } = useContext(RenderContext) || {};
  const [renders, setRenders] = useState([]);

  useEffect(() => { render(itemOrGroup, basket, showPrivacy).then(setRenders); }, [itemOrGroup, showPrivacy]);
  return renders;
}

export default useRender;
