import { useEffect, useState } from 'react';
import { getHivemindTreatment } from '../services';

function useHivemind({ splitId, attributes }) {
  const [treatments, setTreatments] = useState(null);
  useEffect(() => {
    getHivemindTreatment(splitId, attributes)
      .then(treatments => {
        setTreatments(treatments)
      })
      .catch(() => { })
  }, [])
  return treatments;
}

export default useHivemind;