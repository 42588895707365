import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SEARCH_STATUS from '../constants/SearchStatus';
import { getDomainName, getSearchStatus } from '../utilities/domain';
import BaseComponent from './base-component';
import { getSearchResults, getBundleResults } from '../services/DomainServices';

export default class DomainSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      domainName: '',
      searchTerm: props.searchTerm || '',
      prevSearchTerm: props.searchTerm,
      searchStatus: SEARCH_STATUS.None
    };

    this.onChange = this.onChange.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.onChangeText = this.onChangeText.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.searchForDomain = this.searchForDomain.bind(this);

    if (props.searchTerm && props.searchTerm !== '') {
      this.searchForDomain();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.reset !== prevProps.reset && this.props.reset) {
      this.setState({
        prevSearchTerm: '',
        searchTerm: ''
      });
    }
  }

  onChange(event) {
    this.setState({ searchTerm: event.target.value });
  }

  // onKeyPress is only used by the web version of BaseComponent. This is why
  // it does not request that bundles be returned.
  onKeyPress(event) {
    const code = event.keyCode || event.which;
    if (code === 13) {
      this.onSearchClick();
    }
  }

  onChangeText(searchTerm) {
    this.setState({ searchTerm });
  }

  onSearchClick(evt, shouldReturnBundles) {
    const { prevSearchTerm, searchTerm } = this.state;
    const { searchInProgressCallBack } = this.props;

    if (prevSearchTerm !== searchTerm) {
      searchInProgressCallBack && searchInProgressCallBack();

      this.setState({
        prevSearchTerm: searchTerm,
        searchStatus: SEARCH_STATUS.SearchInProgress
      });

      this.searchForDomain(shouldReturnBundles);
    }
  }

  callSearchApi() {
    const { addons, apiKey, excludePromo, numberOfSpins, urlOverride, plid } = this.props;
    const { searchTerm } = this.state;
    const params = { addons, apiKey, excludePromo, numberOfSpins, searchTerm, plid };

    const searchResultsPromise = getSearchResults(params, urlOverride);

    return searchResultsPromise
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  callBundlesApi() {
    const { addons, apiKey, excludePromo, numberOfSpins, urlOverride, plid } = this.props;
    const { searchTerm } = this.state;
    const params = { addons, apiKey, excludePromo, numberOfSpins, searchTerm, plid };

    const bundleResultsPromise = getBundleResults(params, urlOverride);

    return bundleResultsPromise
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  getDomainResults(shouldReturnBundles) {
    const searchResultsPromise = this.callSearchApi();

    // Get Bundles only if shouldReturnBundles
    if (shouldReturnBundles) {
      const bundleResultsPromise = this.callBundlesApi();

      // Combine the bundle & search results if the bundle request succeeded;
      // Otherwise just return the search results.
      return searchResultsPromise
        .then(searchResults => {
          return bundleResultsPromise
            .then(bundlesResultsResponse => {
              return Promise.resolve({
                ...searchResults,
                Bundles: bundlesResultsResponse.data
              });
            })
            .catch(() => {
              return Promise.resolve(searchResults);
            });
        });
    }

    return searchResultsPromise;
  }

  searchForDomain(shouldReturnBundles) {
    const { searchTerm } = this.state;
    const { failureCallback, historyCallback, successCallback } = this.props;
    this.getDomainResults(shouldReturnBundles)
      .then(results => {
        const searchStatus = getSearchStatus(searchTerm, results);
        const domainName = getDomainName(searchTerm, results);
        this.setState({ searchStatus, domainName });
        successCallback && successCallback(results);
        historyCallback && historyCallback(searchTerm);
      }).catch((error) => {
        this.setState({ searchStatus: SEARCH_STATUS.Error });
        failureCallback && failureCallback(error);
        historyCallback && historyCallback(searchTerm);
      });
  }

  render() {
    const { eid, language, showResultsMessage, showRestrictedAsUnavailable, showSearch, urlOverride, plid } = this.props;
    const { searchTerm, searchStatus } = this.state;

    const domainSearchProps = {
      name: "searchTerm",
      value: searchTerm,
      onChange: this.onChange,
      placeholder: language.placeHolderText,
      label: ""
    };

    return (
      <BaseComponent
        domainName={this.state.domainName}
        domainSearchProps={domainSearchProps}
        eid={eid}
        language={language}
        onChangeText={this.onChangeText}
        onKeyPress={this.onKeyPress}
        onSearchClick={this.onSearchClick}
        plid={plid}
        recentSearchQuery={ this.props.recentSearchQuery }
        searchBoxAutoFocus={ this.props.searchBoxAutoFocus }
        searchStatus={searchStatus}
        searchTerm={searchTerm}
        showRestrictedAsUnavailable={showRestrictedAsUnavailable}
        showResultsMessage={showResultsMessage}
        showSearch={showSearch}
        urlOverride={urlOverride}
      />
    );
  }
}

DomainSearch.propTypes = {
  addons: PropTypes.array,
  apiKey: PropTypes.string,
  eid: PropTypes.string,
  excludePromo: PropTypes.bool,
  failureCallback: PropTypes.func,
  historyCallback: PropTypes.func,
  language: PropTypes.object.isRequired,
  numberOfSpins: PropTypes.string,
  plid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  recentSearchQuery: PropTypes.string,
  removeCallback: PropTypes.func,
  reset: PropTypes.bool,
  searchBoxAutoFocus: PropTypes.bool,
  searchInProgressCallBack: PropTypes.func,
  searchTerm: PropTypes.string,
  showRestrictedAsUnavailable: PropTypes.bool,
  showResultsMessage: PropTypes.bool,
  showSearch: PropTypes.bool,
  successCallback: PropTypes.func,
  urlOverride: PropTypes.string
};
