import language from '../../../utils/language';

export default (item, rules = {}) => {
  let description = '';

  if (item.productTypeId === 2 && item?.metadata?.domains)  {
    description = item.label;
  } else if ((item.productTypeId !== 2 && item.productTypeId !== 4) && item?.metadata?.domains) {
    description = item.metadata.domains[0].domain;
  }

  if (rules.description === 'DOMAIN_NAME' && item?.metadata?.domains) {
    description = item.metadata.domains[0].domain;
  } else if (rules.description === 'PRODUCT_NAME')  {
    description = item.label;
  } else if (rules.description)  {
    description = language(`ITEM_DESCRIPTION_${rules.description}`, null, rules.description);
  } else if (rules.description === null)  {
    description = '';
  }

  return description;
};
