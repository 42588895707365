import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MagnifyingGlass from '@ux/icon/magnifying-glass';
import Input from '@ux/form-element';
import Button from '@ux/button';
import SEARCH_STATUS from '../constants/SearchStatus';

const styles = {
  searchBox: {
    position: 'relative'
  },
  searchTextInput: {
    paddingRight: 70
  },
  searchButton: {
    fontSize: 20,
    minWidth: 50,

    position: 'absolute',
    bottom: 0,
    right: 0
  }
};

class SearchComponent extends Component {
  render() {
    return (
      <div style={styles.searchBox}>
        <Input {...this.props.domainSearchProps} onKeyPress={this.props.onKeyPress} style={styles.searchTextInput} />
        <Button style={styles.searchButton} key='searchAction' data-eid={`${this.props.eid}search.click`} design='primary'
          disabled={this.props.searchStatus === SEARCH_STATUS.SearchInProgress} onClick={this.props.onSearchClick}>
          <MagnifyingGlass height={18} fill='white' />
        </Button>
      </div>
    );
  }
}

SearchComponent.propTypes = {
  domainSearchProps: PropTypes.object,
  eid: PropTypes.string,
  onKeyPress: PropTypes.func,
  onSearchClick: PropTypes.func,
  searchStatus: PropTypes.string
};

export default SearchComponent;
