import React from 'react';
import PropTypes from 'prop-types';
import getDesign from '../utils/getDesign';

function ProductRender({ products, onChange }) {

  if (!products?.length) {
    return null;
  }

  return products.map((props, index) => {

    if (!props.design || props.design === 'null') {
      return null;
    }

    const Design = getDesign(props.design);
    return <Design { ...props } key={ index } onChange={ onChange } />;
  });
}

ProductRender.propTypes = {
  onChange: PropTypes.func,
  product: PropTypes.array
};

export default ProductRender;
