import { cookie } from '@ux/util';

const subdomains = {
  'da-DK': {
    subDomain: 'dk'
  },
  'de-AT': {
    subDomain: 'at'
  },
  'de-CH': {
    subDomain: 'ch'
  },
  'de-DE': {
    subDomain: 'de'
  },
  'el-GR': {
    subDomain: 'gr'
  },
  'en-AU': {
    subDomain: 'au'
  },
  'en-CA': {
    subDomain: 'ca'
  },
  'en-GB': {
    subDomain: 'uk'
  },
  'en-HK': {
    subDomain: 'hk',
    language: 'en'
  },
  'en-IE': {
    subDomain: 'ie'
  },
  'en-IN': {
    subDomain: 'in'
  },
  'en-MY': {
    subDomain: 'my'
  },
  'en-NZ': {
    subDomain: 'nz'
  },
  'en-PH': {
    subDomain: 'ph'
  },
  'en-PK': {
    subDomain: 'pk'
  },
  'en-SG': {
    subDomain: 'sg'
  },
  'en-US': {
    subDomain: 'www'
  },
  'en-ZA': {
    subDomain: 'za'
  },
  'es-AR': {
    subDomain: 'ar'
  },
  'es-CL': {
    subDomain: 'cl'
  },
  'es-CO': {
    subDomain: 'co'
  },
  'es-ES': {
    subDomain: 'es'
  },
  'es-MX': {
    subDomain: 'mx'
  },
  'es-PE': {
    subDomain: 'pe'
  },
  'es-US': {
    subDomain: 'www',
    language: 'es'
  },
  'es-VE': {
    subDomain: 've'
  },
  'fi-FI': {
    subDomain: 'fi'
  },
  'fil-PH': {
    subDomain: 'ph',
    language: 'fil'
  },
  'fr-BE': {
    subDomain: 'be',
    language: 'fr'
  },
  'fr-CA': {
    subDomain: 'ca',
    language: 'fr'
  },
  'fr-CH': {
    subDomain: 'ch',
    language: 'fr'
  },
  'fr-FR': {
    subDomain: 'fr'
  },
  'hi-IN': {
    subDomain: 'in',
    language: 'hi'
  },
  'id-ID': {
    subDomain: 'id'
  },
  'it-CH': {
    subDomain: 'ch',
    language: 'it'
  },
  'it-IT': {
    subDomain: 'it'
  },
  'ja-JP': {
    subDomain: 'jp'
  },
  'ko-KR': {
    subDomain: 'kr'
  },
  'mr-IN': {
    subDomain: 'in',
    language: 'mr'
  },
  'ms-MY': {
    subDomain: 'my',
    language: 'ms'
  },
  'nb-NO': {
    subDomain: 'no'
  },
  'nl-BE': {
    subDomain: 'be'
  },
  'nl-NL': {
    subDomain: 'nl'
  },
  'pl-PL': {
    subDomain: 'pl'
  },
  'pt-BR': {
    subDomain: 'br'
  },
  'pt-PT': {
    subDomain: 'pt'
  },
  'qa-PS': {
    subDomain: 'www',
    language: 'qa-ps'
  },
  'qa-PZ': {
    subDomain: 'www',
    language: 'qa-pz'
  },
  'qa-QA': {
    subDomain: 'www',
    language: 'qa-qa'
  },
  'ru-RU': {
    subDomain: 'ru'
  },
  'sv-SE': {
    subDomain: 'se'
  },
  'ta-IN': {
    subDomain: 'in',
    language: 'ta'
  },
  'th-TH': {
    subDomain: 'th'
  },
  'tr-TR': {
    subDomain: 'tr'
  },
  'uk-UA': {
    subDomain: 'ua'
  },
  'vi-VN': {
    subDomain: 'vn'
  },
  'zh-HK': {
    subDomain: 'hk'
  },
  'zh-SG': {
    subDomain: 'sg',
    language: 'zh'
  },
  'zh-TW': {
    subDomain: 'tw'
  },
  'en-AE': {
    subDomain: 'ae'
  },
  'ar-AE': {
    subDomain: 'ae',
    language: 'ar'
  },
  'en-IL': {
    subDomain: 'il',
    language: 'en'
  }
};

export default (host) => {
  const market = cookie.get('market') || 'en-US';
  const subdomainData = subdomains[market] || subdomains['en-US'];
  let url = `https://${subdomainData.subDomain}.${host}`;

  if (subdomainData.language) {
    url = `${url}/${subdomainData.language}`;
  }

  return url;
};
