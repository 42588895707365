import React from 'react';

const RenderContext = React.createContext();

// eslint-disable-next-line react/prop-types
function RenderProvider({ basket, showPrivacy, children }) {
  return (
    <RenderContext.Provider value={{ basket, showPrivacy }}>
      {children}
    </RenderContext.Provider>
  );
}

export {
  RenderProvider,
  RenderContext
};
