import React from 'react';
import PropTypes from 'prop-types';
import Button from '@ux/button';
import Tooltip from '@ux/tooltip';
import { Flex, Card, Text } from '@px/react-utils';
import { styled } from 'linaria/react';

const ExtendedFlex = styled(Flex)`
  flex: 1 0 230px!important;
`;

function PrivacyXsell(props) {
  const { buttonText, description, label, metadata, price, priceDescription, onChange, toolTipText } = props;
  const handleChange = (e) => {
    const update = { ...e };
    if (e.metadata || metadata) {
      update.metadata = { ...e.metadata, ...metadata };
    }
    onChange && onChange(update);
  };

  return (
    <React.Fragment>
      <Card stack>
        <Flex wrap justify={ 'between' }>
          <ExtendedFlex column bg='warning-highlight' p='large'>
            <Text small m={{ x: 'medium' }} >
              {description}
              {toolTipText &&
                <Tooltip
                  className='px-privacy'
                  message={ <div dangerouslySetInnerHTML={{ __html: toolTipText }}></div> }
                  data-eid='stx.checkout.xsell.tooltip.click'>
                </Tooltip>
              }
            </Text>
          </ExtendedFlex>
          <ExtendedFlex column p='large'>
            <Text bold small>{label}</Text>
            <Text color='primary-o' small m={{ top: 'xsmall' }}><Text bold>{price}</Text>{priceDescription}</Text>
            <Flex column alignEnd m={{ top: 'medium' }}>
              <Button
                design='default'
                size='small'
                data-eid='stx.checkout.xsell.add.click'
                onClick={ () => handleChange({ add: true }) }
              >
                {buttonText}
              </Button>
            </Flex>
          </ExtendedFlex>
        </Flex>
      </Card>
    </React.Fragment>
  );
}

PrivacyXsell.propTypes = {
  buttonText: PropTypes.string,
  description: PropTypes.string,
  label: PropTypes.string,
  price: PropTypes.string,
  priceDescription: PropTypes.array,
  onChange: PropTypes.func,
  metadata: PropTypes.object,
  toolTipText: PropTypes.string
};

export default PrivacyXsell;
