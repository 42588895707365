export const ENVIRONMENTS = {
  DEV: 'dev',
  TEST: 'test',
  OTE: 'ote',
  PROD: 'prod'
};

export const SERVICES = {
  ACCOUNT: 'account',
  AKAMAI_URL: 'akamaiUrl',
  AVAILCHECK: 'availcheck',
  CONSTRAINTS_SERVICE: 'constraintsService',
  CONTENT_API: 'contentApi',
  GATEWAY: 'gateway',
  GUI_SERVICE: 'guiPcjsonApi',
  MYA_API: 'myaApi',
  ORDERS_SERVICE: 'ordersService',
  PAYMENT_API: 'paymentApi',
  PLATFORM_API: 'platformApi',
  QUERIES_SERVICE: 'queriesService',
  RECEIPTS_SERVICE: 'receiptsService',
  SALES_PRODUCTS_API: 'salesProductsApi',
  SUBSCRIPTIONS_SERVICE: 'subscriptionsService',
  TLD_API: 'tldApi',
  SUPPORT: 'support',
  DOMAINS: 'domains',
  FOS_API: 'fosApi'
};

export const SITES = {
  HOST: 'host',
  CART: 'cart',
  HOME_PAGE: 'homePage',
  MY_ACCOUNT: 'myAccount',
  PAYMENT_FRAME: 'paymentFrame',
  REFUND_URL: 'refundUrl'
};

export const KEYS = {
  SPLIT_IO_BROWSER: 'splitIoBrowser'
};

export default { ...ENVIRONMENTS, ...SERVICES, ...SITES, ...KEYS };
