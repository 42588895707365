import React from 'react';
import PropTypes from 'prop-types';

export const PrivacyExample = (props) => {
  return (
    <div>
      <div><strong>{props.labelForInfoWithoutPrivacy}</strong></div>
      <address>
        Jane Smith<br />
        jane@BusinessExample.com<br />
        1234 Elm Street<br />
        Hometown, AZ 85000<br />
        (480) 555-5555<br />
      </address>
      <div><strong>{props.labelForInfoWithPrivacy}</strong></div>
      <address>
        DomainsByProxy.com<br />
        ProxiedDomain@DomainsByProxy.com<br />
        14747 N Northsight Blvd<br />
        Suite 111, PMB 309<br />
        Scottsdale, AZ 85260<br />
        +1.480.642.2599<br />
      </address>
    </div>
  );
};

PrivacyExample.propTypes = {
  labelForInfoWithoutPrivacy: PropTypes.string,
  labelForInfoWithPrivacy: PropTypes.string
};

export default PrivacyExample;
