/* eslint-disable react/prop-types */
import React from 'react';
import classnames from 'classnames';

const createStyled = (tag, displayName) => (...styles) => {
  const Component = (props) => {
    const combined = styles.map(style => style(props));
    const className = classnames(combined, props.className);
    const newProps = {};
    if (className) newProps.className = className;
    if (props.onClick) newProps.onClick = props.onClick;
    return React.createElement(tag, newProps, props.children);
  };
  Component.displayName = displayName;
  return Component;
};



export default createStyled;
