import { styled } from 'linaria/react';

const RTL = styled.span``;
export default styled(RTL)`
  :global() {
    html[dir="rtl"] ${RTL} svg {
      transform: scaleX(-1);
    }
  }
`;
