import { SERVICES } from './constants';

export default {
  [SERVICES.ACCOUNT]: `/account`,
  [SERVICES.CONTENT_API]: `/contentapi`,
  [SERVICES.CONSTRAINTS_SERVICE]: `/gateway`,
  [SERVICES.GATEWAY]: `/gateway`,
  [SERVICES.GUI_SERVICE]: `/gui`,
  [SERVICES.ORDERS_SERVICE]: `/gateway`,
  [SERVICES.PAYMENT_API]: `/payapi`,
  [SERVICES.PLATFORM_API]: `/platapi`,
  [SERVICES.QUERIES_SERVICE]: `/gateway`,
  [SERVICES.RECEIPTS_SERVICE]: `/gateway`,
  [SERVICES.SALES_PRODUCTS_API]: `/sapi`,
  [SERVICES.SUBSCRIPTIONS_SERVICE]: `/gateway`,
  [SERVICES.TLD_API]: `/tldapi`,
  [SERVICES.SUPPORT]: `/supportapi`
};
