import createStyled from '../createStyled';
import spacing from '../styles/spacing';
import border from '../styles/border';
import visibility from '../styles/visibility';
import flexbox from '../styles/flexbox';
import background from '../styles/background';
import sizing from '../styles/sizing';

const Flex = createStyled('div', 'Flex')(
  flexbox,
  spacing,
  sizing,
  background,
  visibility,
  border
);

export default Flex;
