import Design1 from '../components/Design1';
import Design2 from '../components/Design2';
import Privacy from '../components/Privacy';
import DesignBulk1 from '../components/DesignBulk1';

const designMap = {
  1: Design1,
  2: Design2,
  Privacy: Privacy,
  bulk: DesignBulk1
};

export default (design) => {
  return designMap[design] || Design1;
};
