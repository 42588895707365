import React from 'react';
import PropTypes from 'prop-types';
import useHivemind from '../hooks/useHivemind';

function Hivemind({ splitId, attributes, children }) {
  const treatment = useHivemind({ splitId, attributes });
  return (
    <React.Fragment>
      {children(treatment)}
    </React.Fragment>
  );
}

Hivemind.propTypes = {
  splitId: PropTypes.string.isRequired,
  attributes: PropTypes.object,
  children: PropTypes.func
};

Hivemind.defaultProps = {
  customerIdType: 'visitor',
  attributes: { }
}

export default Hivemind;