import { css } from 'linaria';

export const classes = {
  'width-10%': css`width: 10% !important;`,
  'width-20%': css`width: 20% !important;`,
  'width-30%': css`width: 30% !important;`,
  'width-40%': css`width: 40% !important;`,
  'width-50%': css`width: 50% !important;`,
  'width-60%': css`width: 60% !important;`,
  'width-70%': css`width: 70% !important;`,
  'width-80%': css`width: 80% !important;`,
  'width-90%': css`width: 90% !important;`,
  'width-100%': css`width: 100% !important;`,
  'height-10%': css`height: 10% !important;`,
  'height-20%': css`height: 20% !important;`,
  'height-30%': css`height: 30% !important;`,
  'height-40%': css`height: 40% !important;`,
  'height-50%': css`height: 50% !important;`,
  'height-60%': css`height: 60% !important;`,
  'height-70%': css`height: 70% !important;`,
  'height-80%': css`height: 80% !important;`,
  'height-90%': css`height: 90% !important;`,
  'height-100%': css`height: 100% !important;`,
  'min-width-10%': css`min-width: 10% !important;`,
  'min-width-20%': css`min-width: 20% !important;`,
  'min-width-30%': css`min-width: 30% !important;`,
  'min-width-40%': css`min-width: 40% !important;`,
  'min-width-50%': css`min-width: 50% !important;`,
  'min-width-60%': css`min-width: 60% !important;`,
  'min-width-70%': css`min-width: 70% !important;`,
  'min-width-80%': css`min-width: 80% !important;`,
  'min-width-90%': css`min-width: 90% !important;`,
  'min-width-100%': css`min-width: 100% !important;`,
  'min-height-10%': css`min-height: 10% !important;`,
  'min-height-20%': css`min-height: 20% !important;`,
  'min-height-30%': css`min-height: 30% !important;`,
  'min-height-40%': css`min-height: 40% !important;`,
  'min-height-50%': css`min-height: 50% !important;`,
  'min-height-60%': css`min-height: 60% !important;`,
  'min-height-70%': css`min-height: 70% !important;`,
  'min-height-80%': css`min-height: 80% !important;`,
  'min-height-90%': css`min-height: 90% !important;`,
  'min-height-100%': css`min-height: 100% !important;`,
  'max-width-10%': css`max-width: 10% !important;`,
  'max-width-20%': css`max-width: 20% !important;`,
  'max-width-30%': css`max-width: 30% !important;`,
  'max-width-40%': css`max-width: 40% !important;`,
  'max-width-50%': css`max-width: 50% !important;`,
  'max-width-60%': css`max-width: 60% !important;`,
  'max-width-70%': css`max-width: 70% !important;`,
  'max-width-80%': css`max-width: 80% !important;`,
  'max-width-90%': css`max-width: 90% !important;`,
  'max-width-100%': css`max-width: 100% !important;`,
  'max-height-10%': css`max-height: 10% !important;`,
  'max-height-20%': css`max-height: 20% !important;`,
  'max-height-30%': css`max-height: 30% !important;`,
  'max-height-40%': css`max-height: 40% !important;`,
  'max-height-50%': css`max-height: 50% !important;`,
  'max-height-60%': css`max-height: 60% !important;`,
  'max-height-70%': css`max-height: 70% !important;`,
  'max-height-80%': css`max-height: 80% !important;`,
  'max-height-90%': css`max-height: 90% !important;`,
  'max-height-100%': css`max-height: 100% !important;`
};

export default (props = {}) => ({
  [classes[`width-${props.width}`]]: props.width,
  [classes[`height-${props.height}`]]: props.height,
  [classes[`min-width-${props.minWidth}`]]: props.minWidth,
  [classes[`min-height-${props.minHeight}`]]: props.minHeight,
  [classes[`max-width-${props.maxWidth}`]]: props.maxWidth,
  [classes[`max-height-${props.maxHeight}`]]: props.maxHeight
});
