import getDuration from './conversions/duration';
import getQuantity from './conversions/quantity';
import getLabel from './conversions/label';
import getDescription from './conversions/description';
import getDescription2 from './conversions/description2';
import getPricing from './conversions/pricing';
import getBulk from './conversions/bulk';
import getXsell from './conversions/xsell';
import pickBy from 'lodash.pickby';
import getRules from './rules';

async function renderBasketItem(item, basket, groupKey, showPrivacy = false) {
  const rules = await getRules(item);
  const render = {
    design: rules.design,
    label: getLabel(item, rules),
    description: getDescription(item, rules),
    description2: getDescription2(item, rules),
    ...getPricing(item, rules),
    quantity: getQuantity(item, rules),
    duration: getDuration(item, rules),
    removable: true,
    metadata: {
      context: 'basket-item',
      itemId: item.itemId,
      groupKey
    }
  };

  const xsellRender = getXsell(item, basket, rules, showPrivacy);
  const bulkRender = getBulk(item, rules);

  return [pickBy(render, property => !!property), xsellRender, bulkRender].filter(obj => !!obj);
}

export default renderBasketItem;
