/* eslint-disable quote-props */
import { css } from 'linaria';

export const classes = {
  grow: [
    css`flex-grow: 0 !important;`,
    css`flex-grow: 1 !important;`,
    css`flex-grow: 2 !important;`,
    css`flex-grow: 3 !important;`,
    css`flex-grow: 4 !important;`,
    css`flex-grow: 5 !important;`,
    css`flex-grow: 6 !important;`,
    css`flex-grow: 7 !important;`,
    css`flex-grow: 8 !important;`,
    css`flex-grow: 9 !important;`,
    css`flex-grow: 10 !important;`
  ],
  shrink: [
    css`flex-shrink: 0 !important;`,
    css`flex-shrink: 1 !important;`,
    css`flex-shrink: 2 !important;`,
    css`flex-shrink: 3 !important;`,
    css`flex-shrink: 4 !important;`,
    css`flex-shrink: 5 !important;`,
    css`flex-shrink: 6 !important;`,
    css`flex-shrink: 7 !important;`,
    css`flex-shrink: 8 !important;`,
    css`flex-shrink: 9 !important;`,
    css`flex-shrink: 10 !important;`
  ]
};

export default (props = {}) => ({

  'd-flex': true,
  'flex-row': props.row,
  'flex-column': props.column,
  'flex-wrap': props.wrap,

  /* grow and shrink */
  [classes.grow[props.grow]]: props.grow,
  [classes.shrink[props.shrink]]: props.shrink,

  /* justify-content */
  'justify-content-start': props.justifyStart,
  'justify-content-end': props.justifyEnd,
  'justify-content-center': props.justifyCenter,
  'justify-content-between': props.justifyBetween,
  'justify-content-around': props.justifyAround,
  [`justify-content-${props.justify}`]: props.justify,

  /* align-items */
  'align-items-start': props.alignStart,
  'align-items-end': props.alignEnd,
  'align-items-center': props.alignCenter,
  'align-items-baseline': props.alignBaseline,
  'align-items-stretch': props.alignStretch,
  [`align-items-${props.align}`]: props.align

});
