import React from 'react';
import PropTypes from 'prop-types';
import BuyButton from './BuyButton';
import Tooltip from '@ux/tooltip';
import HelpIcon from '@ux/icon/help';

const DomainResult = (props) => {
  const { language, hasPromo, isPremium, cssClasses, hasSalePrice, listPriceDisplay, salePriceDisplay, includePrivacy } = props;
  const domainName = props.domain.Fqdn;
  let promoDiscount, premium = "";

  //TMS Promo Text
  if (hasPromo && !isPremium) {
    const promoText = language.promoDiscount.replace('{0}', salePriceDisplay).replace('{1}', listPriceDisplay);
    promoDiscount = (
      <div className="promo-discount text-muted">{promoText}</div>
    );
  }

  //Premium ToolTip Display
  if (isPremium) {
    premium = (
      <Tooltip id='premium-tooltip' title={language.premiumToolTipTitle} message={language.premiumToolTipText}>
        <span>{language.premiumDomain} <span className="help-icon"><HelpIcon weight={16} height={16} /></span>
        </span>
      </Tooltip>
    );
  }

  let classes = "domain-result";
  if (cssClasses) {
    classes = classes.concat(" ", cssClasses);
  }

  return (
    <div className={classes}>
      <div className="domain-name">
        {domainName}
        {premium}
      </div>
      <BuyButton {...props}
        buttonText={language.buyButtonText}
        renewalText={language.renewalText}
        unavailableText={language.domainFailedAvailCheck}
        verifyingText={language.verifyingAvailability}
        addedText={language.addedText}
        hasSalePrice={hasSalePrice}
        listPriceDisplay={listPriceDisplay}
        salePriceDisplay={salePriceDisplay}
        includePrivacy={includePrivacy}
      />
      {promoDiscount}
    </div>
  );
};

DomainResult.propTypes = {
  apiKey: PropTypes.string.isRequired,
  cssClasses: PropTypes.string,
  vcartNavigation: PropTypes.object,
  vcartOptions: PropTypes.object,
  domain: PropTypes.object.isRequired,
  eid: PropTypes.string.isRequired,
  hasPromo: PropTypes.bool,
  isc: PropTypes.string,
  isPremium: PropTypes.bool,
  language: PropTypes.object.isRequired,
  hasSalePrice: PropTypes.bool,
  listPriceDisplay: PropTypes.string,
  salePriceDisplay: PropTypes.string,
  premiumPriceDisplay: PropTypes.string,
  trackingCode: PropTypes.string,
  urlOverride: PropTypes.string,
  redirectToVCart: PropTypes.bool,
  successCallback: PropTypes.func,
  failureCallback: PropTypes.func,
  unavailableCallback: PropTypes.func,
  removeCallback: PropTypes.func,
  buttonDesign: PropTypes.string,
  isDisabled: PropTypes.bool,
  skipAvailCheck: PropTypes.bool,
  market: PropTypes.string,
  currency: PropTypes.string,
  plid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  includePrivacy: PropTypes.bool
};

export default DomainResult;
