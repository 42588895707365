import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@ux/button';
import Tooltip from '@ux/tooltip';
import VirtualCartFormPost from '../vcart-post';
import { getDisplayPrice } from '../utilities/PriceHelper';

export default class Addon extends Component {
  constructor(props) {
    super(props);

    this.state = {
      payload: {},
      isReadyForCart: false
    };

    this.formatPrice = this.formatPrice.bind(this);
    this.onAddClick = this.onAddClick.bind(this);
    this.onDeclineClick = this.onDeclineClick.bind(this);
    this.provideCartPayload = this.provideCartPayload.bind(this);
  }

  formatPrice(microunits, displayPrice) {
    const { currency, market } = this.props;
    // while a zero price should be a valid price, the API is returning zero when microunits are not available
    if (microunits && microunits > 0) {
      const microPrice = getDisplayPrice(microunits, market, currency);
      if (microPrice) {
        return microPrice;
      }
    }
    return displayPrice;
  }

  onAddClick() {
    this.provideCartPayload(true);
  }

  onDeclineClick() {
    this.provideCartPayload(false);
  }

  provideCartPayload(isAddingPrivacy) {
    const { addonData, cartPostData, selectedDomains, vcartNavigation, vcartOptions, addonSuccessCallback } = this.props;

    const cartPayload = JSON.parse(JSON.stringify(cartPostData));

    const postData = {
      cart: cartPayload.cart,
      navigation: vcartNavigation,
      options: vcartOptions
    };

    if (isAddingPrivacy) {
      let pfid, domain, privacyData;
      const len = selectedDomains.length;
      for (var i = 0; i < len; i++) {
        pfid = parseInt(addonData.PfId, 10);
        domain = selectedDomains[i];
        privacyData = { pfid, metadata: { domains: [{ domain: domain }] } };
        postData.cart.items.push(privacyData);
      }
    }

    this.setState({
      payload: postData,
      isReadyForCart: true
    });

    addonSuccessCallback && addonSuccessCallback(postData);
  }

  render() {
    const { addonData, eid, language, redirectToVCart } = this.props;
    const { isReadyForCart, payload } = this.state;

    if (!addonData) return null;

    const privacyExample = (
      <div>
        <div><strong>{language.infoWithoutPrivacy}</strong></div>
        <address>
          Jane Smith<br />
          jane@BusinessExample.com<br />
          1234 Elm Street<br />
          Hometown, AZ 85000<br />
          (480) 555-5555<br />
        </address>
        <div><strong>{language.infoWithPrivacy}</strong></div>
        <address>
          DomainsByProxy.com<br />
          ProxiedDomain@DomainsByProxy.com<br />
          14747 N Northsight Blvd<br />
          Suite 111, PMB 309<br />
          Scottsdale, AZ 85260<br />
          +1.480.642.2599<br />
        </address>
      </div>
    );

    const privacyDetails = (
      <ul>
        <li>{language.privacyTipHides}</li>
        <li>{language.privacyTipPrevents}</li>
        <li>{language.privacyTipHijack}</li>
        <li>{language.privacyTipProtect}</li>
      </ul>
    );

    const currentPrice = this.formatPrice(addonData.CurrentPrice, addonData.CurrentPriceDisplay);
    let privacyPricingBlock = (
      <div className="addon-pricing">
        <div className="text-primary-o font-primary-bold"><span className="display-price">{currentPrice}</span>{language.domainPerYear}</div>
      </div>
    );

    if (addonData.CurrentPrice !== addonData.ListPrice) {
      privacyPricingBlock = (
        <div className="addon-pricing">
          <div className="text-primary-o font-primary-bold"><span className="display-price">{currentPrice}</span>{language.domainPerYear}</div>
          <span className="list-price">{this.formatPrice(addonData.ListPrice, addonData.ListPriceDisplay)}</span>
        </div>
      );
    }

    return (
      <div className="addon-card">
        <h2 className="font-primary-bold">{language.addPrivacy}</h2>
        <div>
          {language.whyPrivacy}
          <span className="text-primary">{language.seeExample}</span>
          <Tooltip
            message={privacyExample}
            data-eid={`${eid}-privacy-tooltip-link.click`}>
          </Tooltip>
          {language.seeExample}
        </div>

        <div className="recommend-privacy">
          {language.recommendPrivacy}
        </div>
        <div className="price-wrapper">
          <div className="addon-details">
            <h3 className="font-primary-bold">{language.privacyProtection}</h3>
            <span className="text-primary">{language.viewDetails}</span>
            <Tooltip
              id="addonDetailsTip"
              message={privacyDetails}
              data-eid={`${eid}-addonDetailsTip-link.click`}>
            </Tooltip>
          </div>
          {privacyPricingBlock}
        </div>
        <div className="button-wrapper">
          <Button id='addCrossSellAction' key='addCrossSellAction' data-eid={`${eid}add.click`} design='primary' onClick={this.onAddClick}>
            {language.addPrivacyBtn}
          </Button>
          <Button id='noThanksCrossSell' key='noThanksCrossSell' data-eid={`${eid}no-thanks.click`} design='default' onClick={this.onDeclineClick}>
            {language.noThanks}
          </Button>
          {isReadyForCart && redirectToVCart && <VirtualCartFormPost {...this.props} payload={payload} shouldSubmitForm />}
        </div>
      </div>
    );
  }
}

Addon.propTypes = {
  addonData: PropTypes.object,
  addonSuccessCallback: PropTypes.func,
  cartPostData: PropTypes.object,
  currency: PropTypes.string,
  domain: PropTypes.object,
  eid: PropTypes.string.isRequired,
  isc: PropTypes.string,
  language: PropTypes.object.isRequired,
  market: PropTypes.string,
  plid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  redirectToVCart: PropTypes.bool,
  selectedDomains: PropTypes.array,
  urlOverride: PropTypes.string,
  vcartNavigation: PropTypes.object,
  vcartOptions: PropTypes.object
};
