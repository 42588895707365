import createStyled from '../createStyled';
import spacing from '../styles/spacing';
import typography from '../styles/typography';
import background from '../styles/background';
import visibility from '../styles/visibility';

const Text = createStyled('span', 'Text')(
  typography,
  background,
  visibility,
  spacing
);

export default Text;
