import React from 'react';
import PropTypes from 'prop-types';
import DropdownPlan from './dropdown-plan';
import Dropdown, { DropdownItem } from '@ux/dropdown';
import { getPriceFromPlan } from '../utilities/PriceHelper';

const DropdownPlanGroup = ({ name, plans, defaultSelected, onChange, pricePerText, eid, market, currency }) => {
  return (
    <div data-eid={eid}>
      <Dropdown type="select" name={name} onChange={e => onChange(e, e.value)} defaultSelected={defaultSelected}>
        {plans && plans.map((plan, key) =>
          <DropdownItem key={key} value={plan.callbackData} data-eid={plan.eid} disabled={false}>
            <DropdownPlan
              currentPriceDisplay={getPriceFromPlan(plan, "current", market, currency)}
              listPriceDisplay={getPriceFromPlan(plan, "list", market, currency)}
              pricePerText={pricePerText} productName={plan.productName} features={plan.features} />
          </DropdownItem>
        )}
      </Dropdown>
    </div>
  );
};

DropdownPlanGroup.propTypes = {
  defaultSelected: PropTypes.number,
  eid: PropTypes.string,
  name: PropTypes.string.isRequired,
  plans: PropTypes.array,
  pricePerText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  market: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired
};

export default DropdownPlanGroup;
