
export function calculateDuration(duration, fromUnit, toUnit) {
  if (duration === null || typeof duration === 'undefined') {
    throw Error('duration cannot be null or undefined');
  } else if (duration < 0) {
    throw Error('duration cannot be negative');
  }

  let value = duration;
  if (fromUnit === 'YEAR' && toUnit === 'MONTH') {
    value = duration * 12;
  } else if (fromUnit === 'MONTH' && toUnit === 'YEAR') {
    value = duration / 12;
  }
  return value;
}

export function calculatePrice(microunits, fromUnit, toUnit) {

  if (microunits === null || typeof microunits === 'undefined') {
    throw Error('price cannot be null or undefined');
  } else if (microunits < 0) {
    throw Error('price cannot be negative');
  }

  let value = microunits;
  if (fromUnit === 'YEAR' && toUnit === 'MONTH') {
    value = microunits / 12;
  } else if (fromUnit === 'MONTH' && toUnit === 'YEAR') {
    value = microunits * 12;
  }
  return value;
}

export function calculateSavings(price, listPrice, type) {
  let savings;
  if (type === 'PERCENT') {
    savings = Math.floor(((listPrice - price) / listPrice) * 100);
  } else if (type === 'AMOUNT') {
    savings = listPrice - price;
  } else {
    throw Error('type must be either PERCENT or AMOUNT');
  }
  return savings;
}
