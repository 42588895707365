import { cookie } from '@ux/util';

export default (value) => {
  try {
    const market = cookie.get('market');
    return new Intl.DateTimeFormat(`${market}-u-nu-latn`).format(new Date(value));
  } catch {
    return value;
  }
};
