import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from 'linaria/react';
import OkIcon from '@ux/icon/ok';
import Radio from '@ux/radio';
import { Flex, Text, Card, IconRTL } from '@px/react-utils';

const RadioCard = styled(Card)`
  cursor: pointer;
  overflow: hidden;
  .custom-control {
    padding-left: 1.5rem;
    margin: -.1rem -.1rem .1rem .1rem;
  }
  .form-check { margin: 0; }
  .form-group { margin: 0; }
`;

function DurationWithRadioButtons({ value, label, options, onChange, quantityLabel }) {
  const [selectedLabel, setSelectedLabel] = useState(label);

  if (!value && !label) {
    return null;
  }

  if (!options?.length) {
    return label;
  }

  const handleChange = (option) => {
    setSelectedLabel(option.label);
    if (option.value !== value) {
      onChange && onChange({ duration: option.value, metadata: option.metadata });
    }
  };

  return options.map(option => {
    const checked = selectedLabel === option.label;
    return (
      <RadioCard key={ option.label } bd={ checked && 'primary-midrange' } m={{ y: 'small' }}>
        <Flex onClick={ () => handleChange(option) } data-eid='stx.checkout.product.duration.update' >
          <Flex width='50%' bg={ checked && 'primary-midrange' }>
            <Flex row alignCenter grow={ 1 } m={{ y: 'xsmall', right: 'small'  }}>
              <Flex align='center' m={{ x: 'small' }}>
                {checked
                  ? <IconRTL><OkIcon /></IconRTL>
                  : <Radio label=''/>
                }
              </Flex>
              <Flex column>
                <Text color={ checked && 'white' } bold={ checked }>{option.label}</Text>
                <Text small color={ checked ? 'white' : 'muted' }>
                  {option.unitPrice}&nbsp;<wbr/>
                  <s>{option.unitListPrice}</s>
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex width='50%' bg='white'>
            <Flex column alignEnd justifyCenter grow={ 1 } m={{ y: 'xsmall', right: 'small' }}>
              <Text align='right' color={ checked && 'primary-o' } bold>{option.price}</Text>
              <Text align='right' small color='muted'>{quantityLabel}</Text>
              <Text align='right' small color='muted'>{option.savings}</Text>
            </Flex>
          </Flex>
        </Flex>
      </RadioCard>
    );
  });
}

DurationWithRadioButtons.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.number,
  label: PropTypes.string,
  quantityLabel: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    price: PropTypes.string,
    unitPrice: PropTypes.string,
    savings: PropTypes.string
  }))
};

export default DurationWithRadioButtons;
