export default ({
  match: (item) => {
    return (
      item.productTypeId === 536 && item.periodUnit === 'FREEMIUM'
    );
  },
  rules: {
    design: '1',
    description: 'WSB_FREEMIUM',
    description2: 'WSB_FREEMIUM_SUBTEXT'
  }
});
