/* eslint-disable max-statements */
import language from '../../../utils/language';
import money from '../../../utils/money';
import date from '../../../utils/date';
import { calculatePrice } from '../../../utils/calculations';

export default (item, rules) => {
  const pricing = {};
  pricing.price = money(item.pricing.subtotal);
  pricing.priceDescription = getPriceDescription(item, rules);

  if (item.pricing.subtotal > 0) {
    pricing.unitPrice = getUnitPrice(item.pricing.subtotal / item.period, item.periodUnit, rules.durationUnit);
  }

  const autoRenews = item.renewAuto !== false || (item?.metadata?.domains && item.metadata.domains[0].renewAuto);
  if (autoRenews && item.pricing.renewal && item.pricing.renewal.subtotal > 0) {
    pricing.renewalPrice = getRenewalPrice(item.pricing.renewal.subtotal / item.period,
      item.periodUnit,
      rules.durationUnit,
      rules.quantityUnit
    );
  }

  if (item.pricing.subtotal !== item.pricing.list) {
    pricing.listPrice = money(item.pricing.list);
    pricing.unitListPrice = getUnitPrice(item.pricing.list / item.period, item.periodUnit, rules.durationUnit);
  }

  return pricing;
};

function getUnitPrice(microunits, fromUnit, toUnit) {
  const price = calculatePrice(microunits, fromUnit, toUnit);
  return language(`PRICE_UNIT_${toUnit}`, { price: money(price) });
}

function getRenewalPrice(microunits, fromUnit, toUnit, quantityUnit) {
  const price = calculatePrice(microunits, fromUnit, toUnit);
  if (quantityUnit) {
    const quantityLabel = language(`QUANTITY_${quantityUnit}`);
    return language(`PRICE_RENEWAL_UNIT_QUANTITY_${toUnit}`, { price: money(price), quantityLabel });
  }
  return language(`PRICE_RENEWAL_UNIT_${toUnit}`, { price: money(price) });
}

function getPriceDescription(item, rules) {
  let priceDescription;

  if (item.pricing?.unit?.ranges && rules.durationUnit === 'YEAR') {
    const firstYearPrice = money(item.pricing.unit.ranges[0].subtotal);
    const secondYearPrice = money(item.pricing.unit.ranges[1].subtotal);
    priceDescription = [
      language('PRICE_DESCRIPTION_FIRST_YEAR', { price: firstYearPrice }),
      language('PRICE_DESCRIPTION_SECOND_YEAR', { price: secondYearPrice })
    ];
  } else if (item.pricing.subtotal === 0 && item.billOn) {
    priceDescription = [
      language('PRICE_DESCRIPTION_FREE_TRIAL', { date: date(item.billOn) })
    ];
  } else if (item.pricing.subtotal === 0 && item.canRemoveAlone) {
    if (item.periodUnit === 'YEAR') {
      const billOn = new Date(new Date().setFullYear(new Date().getFullYear() + item.period));
      priceDescription = [
        language('PRICE_DESCRIPTION_FREE_TRIAL', { date: date(billOn) })
      ];
    } else if (item.periodUnit === 'MONTH') {
      const billOn = new Date(new Date().setMonth(new Date().getMonth() + item.period));
      priceDescription = [
        language('PRICE_DESCRIPTION_FREE_TRIAL', { date: date(billOn) })
      ];
    }
  }

  return priceDescription;
}
