import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@ux/tooltip';

export const ExampleTooltip = (props) => {
  return (
    <span className="text-primary">
      <Tooltip
        message={props.content}
        data-eid={props.eid}>
        <span>{props.linkText}</span>
      </Tooltip>
    </span>
  );
};

ExampleTooltip.propTypes = {
  linkText: PropTypes.string,
  content: PropTypes.object,
  eid: PropTypes.string
};

export default ExampleTooltip;
