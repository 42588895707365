import React from 'react';
import PropTypes from 'prop-types';

const DropdownPlan = ({ currentPriceDisplay, listPriceDisplay, pricePerText, productName, description, features }) => {
  let crossedOutPrice, selectedPriceBlock, dropdownPricingBlock = null, featuresList = null;

  if (features) {
    if (typeof (features[0]) === 'object') {
      features = Object.values(features[0]);
    }

    featuresList = (<ul>
      {features.map((item, index) => <li key={'item' + index} dangerouslySetInnerHTML={{ __html: item }}></li>)}
    </ul>);
  }

  if (currentPriceDisplay !== listPriceDisplay) {
    crossedOutPrice = (
      <span className="list-price"><s>{listPriceDisplay}</s></span>
    );
  }

  if (currentPriceDisplay) {
    const currentPriceBlock = <span className="text-primary-o">{currentPriceDisplay}{pricePerText}</span>;
    selectedPriceBlock = <span className="selected-pricing text-primary-o font-base">- {currentPriceBlock}</span>;
    dropdownPricingBlock = (
      <div className="dropdown-details">
        <div className="dropdown-pricing">
          {crossedOutPrice} {currentPriceBlock}
        </div>
        {featuresList}
      </div>
    );
  }

  return (
    <div>
      <h5><span className="selected-plan">{productName}</span> {selectedPriceBlock}</h5>
      {description}
      {dropdownPricingBlock}
    </div>
  );
};

DropdownPlan.propTypes = {
  productName: PropTypes.string,
  description: PropTypes.string,

  currentPriceDisplay: PropTypes.string,
  listPriceDisplay: PropTypes.string,
  pricePerText: PropTypes.string,

  features: PropTypes.array
};

export default DropdownPlan;
