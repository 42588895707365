import React from 'react';
import PropTypes from 'prop-types';

const BaseSearchResult = (props) => {
  const { spins, hasExactMatch, language } = props;
  let spinsHeader;
  if (spins && spins.length > 0) {
    spinsHeader = (
      <h4 className="headline-primary spins-header">
        {hasExactMatch ? language.getOneOfThese : language.howAboutThese}
      </h4>
    );
  }
  return (
    <div className="dpp-results">
      {props.exactResult}
      {spinsHeader}
      {props.spinResults}
    </div>
  );
};

BaseSearchResult.propTypes = {
  hasExactMatch: PropTypes.bool.isRequired,
  spins: PropTypes.array,
  language: PropTypes.object.isRequired,
  exactResult: PropTypes.object,
  spinResults: PropTypes.array
};

export default BaseSearchResult;
