import React from 'react';
import PropTypes from 'prop-types';

const AddOnItem = ({title, description, children}) => {
  return (
    <div className="addon-card">
      {title && <h2 className="font-primary-bold">{title}</h2>}
      {description}
      {children}
    </div>
  );
};

AddOnItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.object,
  children: PropTypes.object
};

export default AddOnItem;
