import React from 'react';
import PropTypes from 'prop-types';
import { trackEvent } from '../services';

const TrackEvent = React.memo(({ eventName, eventValue }) => {
  trackEvent(eventName, eventValue);
  return null;
});

TrackEvent.propTypes = {
  eventName: PropTypes.string.isRequired,
  eventValue: PropTypes.string,
};

export default TrackEvent;
