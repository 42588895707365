import renderBasketItem from './basket/renderBasketItem';
import renderBasketGroup from './basket/renderBasketGroup';

export default (obj, basket, showPrivacy) => {
  if (obj.itemId) {
    return renderBasketItem(obj, basket, null, showPrivacy);
  } else if (obj.groupKey && obj?.items?.length === 1) {
    return renderBasketItem(obj.items[0], basket, obj.groupKey, showPrivacy);
  } else if (obj.groupKey && obj?.items?.length > 1) {
    return renderBasketGroup(obj);
  }

  throw Error('Not yet implemented');
};
