import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BaseSearchResult from './BaseSearchResult';
import DomainResult from './DomainResult';
import { isDomainAvailable } from '../utilities/domain';
import { getPricing, getDisplayPrice } from '../utilities/PriceHelper';


export default class SearchResults extends Component {
  constructor(props) {
    super(props);
  }

  getPrices(priceInfo) {
    const { market, currency } = this.props;

    const list = priceInfo.ListPriceinMicroUnits || priceInfo.ListPrice;
    const sale = priceInfo.CurrencyPriceinMicroUnits || priceInfo.CurrentPrice;
    const hasSalePrice = (sale !== list);

    const listDisplay = getDisplayPrice(priceInfo.ListPriceinMicroUnits, market, currency) || priceInfo.ListPriceDisplay;
    const saleDisplay = getDisplayPrice(priceInfo.CurrencyPriceinMicroUnits, market, currency) || priceInfo.CurrentPriceDisplay;

    return { saleDisplay, listDisplay, hasSalePrice };
  }

  hasExactMatch(exactMatch) {
    if (exactMatch) {
      const isAuction = exactMatch.Inventory === 'auction';
      const isPreReg = exactMatch.TldInfo;
      const isRestricted = exactMatch.IsPurchasable === false && isDomainAvailable(exactMatch); //Available but not for offer
      return isDomainAvailable(exactMatch) && !isAuction && !isPreReg && !isRestricted ||  exactMatch.Inventory === 'premium';
    }
    return false;
  }

  render() { // eslint-disable-line complexity
    const { language, resultsPayload, showBundles, plid } = this.props;

    if (resultsPayload) {
      const exactMatch = resultsPayload.ExactMatchDomain;
      const bundles = resultsPayload.Bundles || [];
      const spins = this.props.showSpins ? resultsPayload.RecommendedDomains : [];
      const hasExactMatch = this.hasExactMatch(exactMatch); //Show exact match
      let exactResult, bundleResults, spinResults;

      // Exact Match Display
      if (hasExactMatch) {
        const priceInfo = getPricing(resultsPayload.Products, exactMatch.Extension, exactMatch.TierId);
        const hasPromo = priceInfo && priceInfo.IsPromoDiscount && priceInfo.PromoRegLengthFlag === 2;
        const isPremium = exactMatch.Inventory === 'premium' && exactMatch.VendorId !== 11 || exactMatch.IsPremiumTier;
        const premiumPriceDisplay = isPremium ? exactMatch.PriceDisplay : "";
        const prices = this.getPrices(priceInfo);
        const headerMessage = isDomainAvailable(exactMatch) && language.domainIsAvailable;
        if (!isPremium) {
          exactResult = (
            <DomainResult
              {...this.props}
              cssClasses='exact-match'
              id={'exact-match-0'}
              domain={exactMatch}
              plid={plid}
              hasPromo={hasPromo}
              hasSalePrice={prices.hasSalePrice}
              headerMessage={headerMessage}
              listPriceDisplay={prices.listDisplay}
              isPremium={isPremium}
              premiumPriceDisplay={premiumPriceDisplay}
              salePriceDisplay={prices.saleDisplay}
            />
          );
        }
      }

      // Bundles Display
      if (showBundles && bundles) {
        bundleResults = bundles.map((item, index) => {
          let headerMessage = language.domainBundleText;
          headerMessage = headerMessage.replace('{0}', item.DotTypesText.match(/,/g).length + 1);
          headerMessage = headerMessage.replace('{1}', item.SavingsText);
          item.Fqdn = item.DotTypesText.replace(/,/g, '\n');
          return (
            <DomainResult
              {...this.props}
              id={'bundle-' + index}
              key={'bundle' + index}
              plid={plid}
              domain={item}
              hasSalePrice={true}
              headerMessage={headerMessage}
              listPriceDisplay={item.Prices.ListPrice}
              salePriceDisplay={item.Prices.CurrentPrice}
            />
          );
        });
      }

      // Spins Headline
      if (spins && spins.length > 0) {
        // Spins Display
        spinResults = spins.map((item, index) => {
          const priceInfo = getPricing(resultsPayload.Products, item.Extension, item.TierId);
          const hasPromo = priceInfo && priceInfo.IsPromoDiscount && priceInfo.PromoRegLengthFlag === 2;
          const isPremium = item.Inventory === 'premium' || item.IsPremiumTier;
          const prices = this.getPrices(priceInfo);
          return !isPremium && (
            <DomainResult
              {...this.props}
              domain={item}
              plid={plid}
              hasPromo={hasPromo}
              isPremium={isPremium}
              id={'spin-' + index}
              key={'spin' + index}
              hasSalePrice={prices.hasSalePrice}
              listPriceDisplay={prices.listDisplay}
              salePriceDisplay={prices.saleDisplay}
            />
          );
        });
      }

      return (
        <BaseSearchResult
          hasExactMatch={hasExactMatch}
          spins={spins}
          language={language}
          exactResult={exactResult}
          bundleResults={bundleResults}
          spinResults={spinResults}
        />
      );
    }
    return null;
  }
}

SearchResults.propTypes = {
  apiKey: PropTypes.string.isRequired,
  eid: PropTypes.string.isRequired,
  successCallback: PropTypes.func,
  failureCallback: PropTypes.func,
  unavailableCallback: PropTypes.func,
  isc: PropTypes.string,
  language: PropTypes.object.isRequired,
  resultsPayload: PropTypes.object,
  redirectToVCart: PropTypes.bool,
  trackingCode: PropTypes.string,
  urlOverride: PropTypes.string,
  vcartNavigation: PropTypes.object,
  vcartOptions: PropTypes.object,
  buttonDesign: PropTypes.string,
  isDisabled: PropTypes.bool,
  skipAvailCheck: PropTypes.bool,
  showBundles: PropTypes.bool,
  market: PropTypes.string,
  currency: PropTypes.string,
  showSpins: PropTypes.bool,
  plid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  includePrivacy: PropTypes.bool
};

SearchResults.defaultProps = {
  redirectToVCart: true,
  showSpins: true
};
