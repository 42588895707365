import language from '../../../utils/language';

export default (item, rules) => {
  if (rules?.bulkDesign) {
    return {
      design: rules.bulkDesign,
      viewAll: language('BULK_VIEW_ALL', { length: item?.metadata?.domains?.length }),
      minimize: language('BULK_MINIMIZE'),
      bulkProducts: item?.metadata?.domains.map(domain => ({
        label: domain.domain,
        removable: true,
        metadata: {
          context: 'basket-bulk-domain',
          itemId: item.itemId,
          domain: domain.domain
        }
      }))
    };
  }

  return null;
};
