import subDomains from "./subDomains.json";
import jsCookie from 'js-cookie';

class SubDomainService {
  /**
   * Gets the sub domain and language, if available, for the market id
   *
   * @method getSubDomainAndLanguage
   * @returns {JSON} Returns JSON object with subDomain and language keys
   */
  static getSubDomainAndLanguage() {
    const marketCookie = jsCookie.get("market") || "en-US";
    const subDomainData = subDomains[marketCookie] || subDomains["en-US"];
    return { subDomain: subDomainData.subDomain, language: subDomainData.language };
  }

  /**
   * Gets the sub domain and language, if available, for the market id
   *
   * @method getUrl
   * @param {String} host Example: dev-godaddy.com or test-godaddy.com
   * @param {String} path with or without query string params. Example: /api/selected/domains or ?q=chcekingdomain.com
   * @returns {String} Returns the full URL
   */
  static getUrl(host, path) {
    var hostValue = host || "godaddy.com";
    const subDomainData = this.getSubDomainAndLanguage();
    let url = `https://${subDomainData.subDomain}.${hostValue}`;

    if (subDomainData.language) {
      url = url.concat("/", subDomainData.language);
    }

    if (path) {
      url = (~path.indexOf("/")) ? url.concat(path) : url.concat('/', path);
    }

    return url;
  }
}

export default SubDomainService;
