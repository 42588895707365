import language from '../../../utils/language';
import money from '../../../utils/money';

export default (item, rules = {}) => {
  let description = '';
  let values = {};

  if ((item.productTypeId === 536 && item.periodUnit === 'FREEMIUM')) {
    values = { renewal: money(item.pricing.renewal.unit.list) };
  }

  if (rules.description2) {
    description = language(`ITEM_DESCRIPTION_${rules.description2}`, values, rules.description2);
  } else if (rules.description === null)  {
    description = '';
  }

  return description;
};


