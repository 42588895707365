import { css } from 'linaria';

export const classes = {
  overflowHidden: css`
    overflow: hidden;
  `,
  overflowAuto: css`
    overflow: auto;
  `
};

export default (props = {}) => ({
  /* visibility */
  visible: props.visible,
  invisible: props.invisible || props.visible === false,
  hidden: props.hidden,

  /* overflow */
  [classes.overflowHidden]: props.overflow === 'hidden',
  [classes.overflowAuto]: props.overflow === 'auto'
});
