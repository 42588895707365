export default ({
  match: (item) => {
    return item?.tracking?.itemTrackingCode === 'dpp_absol_reoffer5';
  },
  rules: {
    design: '1',
    durationUnit: 'YEAR',
    label: 'PRODUCT_NAME',
    description: 'DOMAIN_NAME',
    priceDescription: 'MULTI_YEAR',
    rollupPricing: true,
    savings: 'PERCENT_OFF'
  }
});
