import React from 'react';
import PropTypes from 'prop-types';
import Dropdown, { DropdownItem } from '@ux/dropdown';
import { styled } from 'linaria/react';
import { Flex, Text, Block } from '@px/react-utils';

const StyledDropdown = styled(Dropdown)`
  flex-grow: 1;
`;

function DurationDropdown({ value, label, options, onChange }) {

  if (!value && !label) {
    return null;
  }

  if (!options?.length) {
    return label;
  }

  const handleChange = (option) => {
    if (option.value !== value) {
      onChange && onChange({ duration: option.value, metadata: option.metadata });
    }
  };

  const selectedIndex = options.findIndex(duration => {
    return duration.value === value;
  });

  return (
    <StyledDropdown enableFullscreen={ false } type='select' onChange={ (e) => handleChange(e.value) } defaultSelected={ selectedIndex }>
      {options.map((duration, index) => (
        <DropdownItem key={ index } value={ duration } data-eid='stx.checkout.product.duration.update'>
          <Flex justifyBetween grow={ 1 }>
            <Text>{duration?.label}</Text>
            <Block margin={{ left: 'small' }}>
              <Text small color='primary-o'>{duration?.savings}</Text>
            </Block>
          </Flex>
        </DropdownItem>
      ))}
    </StyledDropdown>
  );
}

DurationDropdown.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.number,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    savings: PropTypes.string
  }))
};

export default DurationDropdown;
