import { styled } from 'linaria/react';

const CLASS = styled.span``;
export default styled(CLASS)`
  :global() {
    html[dir='rtl'] ${CLASS} {
      .dpp-search {
        .form-group {
          input.form-control {
            text-align: right;
            direction: ltr;
            padding-left: 3.75rem;
            padding-right: .75rem !important;
          }
        }
        button {
          left: 0 !important;
          right: unset !important;
        }
      }
    }
  } /* end of global */

  h3 {
    font-size: .9rem;
    font-weight: var(--uxp-font-weight-bold);
    line-height: 1rem;
    margin: 0;
    width: auto;
  }
  .panel {
    border: solid 1px #EEE;
  }
  .panel-button {
    background-color: lightyellow;
    padding: 10px;
    .icon {
      top:6px;
    }
  }
  .panel-button:after {
    font-size: 1rem;
    right: 10px;
    top: 7px;
  }
  .panel-collapse {
    background-color: transparent;
    border-top: solid 1px #EEE;
  }
  .panel-content {
    margin: 0;
  }
  .dpp-search {
    padding: 20px 20px 10px 20px;
    h3 {
      font-size: .9rem;
      line-height: 1rem;
      font-weight: 700;
    }
    label {
      display: none;
    }

    .form-control {
      font-size: .85rem;
      line-height: 1rem;
    }
    input {
      padding-right:70px;
    }

    button {
      font-size: 20px;
      min-width: 50px;
      padding: 10px;

      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      position: absolute;
      bottom:0;
      right:0;
    }

    h3.label,
    .form-group {
      font-size: .9rem;
      line-height: 1rem;
      margin-bottom: 5px;
    }

    .default-msg {
      .initial-msg {
        color: #CCC;
        margin-top:45px;
        text-align: center;
      }

      span a {
        display: none;
      }

      span.unavailable {
        color:#09757A;
      }
      h4 {
        font-size: .9rem;
        line-height: 1rem;
      }
    }
  }

  .dpp-results {
    .domain-result {
      border: solid 1px #eee;
      display: ${props => props.spinsDisplay};
      margin: 0 20px 10px;
    }
    .domain-result.exact-match {
      margin-bottom: 20px;
      display: block;
    }

    .spins-header {
      border-top: solid 1px #EEE;
      font-size: 1rem;
      font-weight: var(--uxp-font-weight-bold);
      line-height: 1.5rem;
      margin: 0 20px;
      padding-top:10px;
      display: ${props => props.spinsDisplay};
    }

    .domain-result {
      float: none;
      min-width: unset;
      padding: 10px;
      vertical-align: middle;
      width: unset;

      .domain-name {
        display: inline-block;
        font-size: .85rem;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
        width: 65%;
        .uxicon {
          vertical-align: inherit;
        }
      }
      .buy-btn-wrap {
        display: flex;
        width: 100%;
        font-size: .85rem;
        float:none;

        div {
          display: flex;
          width: 100%;
        }

        .unavailable {
          color: #999;
        }

        h6 {
          font-size: .85rem;
          &.headline-primary {
            height: 24px;
            margin: 0;
          }
        }
      }
      .price-block {
        margin-left:0;
        small {
          strong {
            font-weight: normal;
          }
        }
      }
      .dpp-price {
        margin-right: 5px;
      }
      .btn-sm {
        margin-top: -5px; /* some markets are too long, button needs to bump down a bit */
        color: #111!important;
        background: #fff!important;
        border-color: #111!important;
        border-width: 2px;
        padding-top: 2px;
      }
      .btn-sm:hover {
        color: #09757A!important;
      }
    }
  }
  .disclaimer {
    padding: 0 20px 20px;
    font-size: .85rem;
    line-height: 1rem;
    float: none;
    clear: both;
    background-color: transparent;

    .renewal-msg {
      color: #777;
      margin-bottom: 10px;
    }
  }
`;
