import config, { ConfigConstants } from '@px/config';
import { data } from '@ux/data';

export default (update) => {
  if (update.delete) {
    return deleteItem(update);
  } else if (update.add) {
    return addPrivacyItem(update);
  } else if (update.deleteBulk) {
    return deleteBulk(update);
  } else if (update.duration) {
    return updateDuration(update);
  } else if (update.quantity) {
    return updateQuantity(update);
  }
};

function deleteItem({ metadata }) {
  const url = `${config.get(ConfigConstants.CART)}/api/remove?itemId=${metadata.itemId}`;
  const method = 'DELETE';
  const headers = { 'Content-Type': 'application/json' };

  return { url, method, headers, type: 'delete' };
}

function addPrivacyItem({ metadata }) {
  const url = `${config.get(ConfigConstants.CART)}/api/basket`;
  const method = 'PATCH';
  const body = JSON.stringify({
    items: [{ metadata: { domains: [{ domain: metadata.domain }] },
      pfid: metadata.pfid, periodCount: metadata.periodCount }],
    tracking: metadata.tracking
  });
  const headers = { 'Content-Type': 'application/json' };

  return { url, method, body, headers, type: 'addPrivacy' };
}

function deleteBulk({ metadata }) {
  const url = `${config.get(ConfigConstants.CART)}/api/removeBulkDomain?itemId=${metadata.itemId}`;
  const method = 'POST';
  const headers = { 'Content-Type': 'application/json' };
  const body = JSON.stringify([metadata.domain]);

  return { url, method, headers, type: 'deleteBulk', body };
}

function updateDuration({ duration, metadata }) {
  const method = 'PATCH';
  const headers = { 'Content-Type': 'application/json' };
  let url, body;

  if (metadata?.packageId) {
    const term = { termType: metadata.durationUnit, NumberOfTerms: duration };
    url = `${config.get(ConfigConstants.SALES_PRODUCTS_API)}/v1/pl/${data.privateLabelId}/cart/packages`;
    body = JSON.stringify({ itemId: metadata.itemId, pkgid: metadata.packageId, term });
  } else if (metadata?.itemId && metadata?.context === 'basket-item') {
    url = `${config.get(ConfigConstants.CART)}/api/basket`;
    body = JSON.stringify({ items: [{ itemId: metadata.itemId, pfid: metadata.pfid }] });
  } else if (metadata?.itemId && metadata?.context === 'basket-group') {
    url = `${config.get(ConfigConstants.CART)}/api/updateGroup`;
    body = JSON.stringify({ items: [{ itemId: metadata.itemId, pfid: metadata.pfid }] });
  }

  return { url, method, body, headers, type: 'duration' };
}

function updateQuantity({ quantity, metadata }) {
  const url = `${config.get(ConfigConstants.CART)}/api/basket`;
  const method = 'PATCH';
  const body = JSON.stringify({ items: [{ itemId: metadata.itemId, quantity: quantity }] });
  const headers = { 'Content-Type': 'application/json' };
  return { url, method, body, headers, type: 'quantity' };
}
