import SEARCH_STATUS from '../constants/SearchStatus';

/**
 * This file contains methods specific to a domain.
 */

/**
 * Returns domain name from API response if found, otherwise returns search term.
 *
 * @param {string} searchTerm Domain name or search term
 * @param {object} response Response object from API call
 * @returns {string} domain name or search term
 */
export function getDomainName(searchTerm, response) {
  const domain = response && response.ExactMatchDomain;
  const domainName = domain && domain.Fqdn;
  return domainName || searchTerm;
}

export function getSearchStatus(searchTerm, response) { // eslint-disable-line complexity
  let searchStatus = SEARCH_STATUS.Unavailable;
  if (response && response.ExactMatchDomain) {
    const domain = response.ExactMatchDomain;
    const isPremium = isDomainPremium(domain);
    const isAuction = domain.Inventory === 'auction';
    const isPreReg = domain.TldInfo;
    if (!isPremium && domain.AvailabilityStatus === 1001 && !isAuction) {
      searchStatus = SEARCH_STATUS.Taken;
    } else if ((domain.IsPurchasable === false && isDomainAvailable(domain)) || isPremium) {
      searchStatus = SEARCH_STATUS.Restricted;
    } else if (isAuction) {
      searchStatus = SEARCH_STATUS.Auction;
    } else if (isPreReg) {
      searchStatus = SEARCH_STATUS.PreReg;
    } else if ((!isPremium && isDomainAvailable(domain) && !isAuction)) {
      searchStatus = SEARCH_STATUS.Available;
    } else {
      searchStatus = SEARCH_STATUS.Unavailable;
    }
  }
  return searchStatus;
}

export function isDomainPremium(domain) {
  return (domain.Inventory === 'premium' && domain.VendorId !== 11) || domain.IsPremiumTier;
}

export function isDomainPurchaseable(domain) {
  return !isDomainPremium(domain) && domain.IsPurchasable && isDomainAvailable(domain);
}

export function isDomainAvailable(domain) {
  return domain.AvailabilityStatus === 1000;
}
