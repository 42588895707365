import language from '../../../utils/language';

export default (item, rules = {}) => {
  if (item.quantity && item.quantityOptions) {
    const min = rules.quantityMin || item.quantityOptions.minimum;
    const max = rules.quantityMax || item.quantityOptions.maximum;
    return {
      label: language(`QUANTITY_LABEL_${rules.quantityUnit}`, { quantity: item.quantity }),
      errorLabel: language(`QUANTITY_LABEL_ERROR`, { min, max }),
      description: language(`QUANTITY_DESCRIPTION_${rules.quantityUnit}`),
      value: item.quantity,
      options: { min, max }
    };
  }
};
