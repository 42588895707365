import React from 'react';
import PropTypes from 'prop-types';
import RadioPlan from './radio-plan';
import RadioGroup from '@ux/radio-group';
import { getPriceFromPlan } from '../utilities/PriceHelper';

const RadioPlanGroup = ({ name, plans, selectedIndex, pricePerText, pricePerSecondaryText, tooltipLinkText, onChange, market, currency, eidPrefix }) => {
  if (plans) {
    const inputs = [];

    plans.map((plan, index) => {
      inputs.push({
        "label": <RadioPlan id={name + index}
          currentPriceDisplay={getPriceFromPlan(plan, "current", market, currency)}
          listPriceDisplay={getPriceFromPlan(plan, "list", market, currency)}
          pricePerText={pricePerText} productName={plan.productName}
          pricePerSecondaryText={pricePerSecondaryText}
          customPriceText={plan.customPriceText}
          tooltipLinkText={tooltipLinkText}
          features={plan.features}
          planKey={plan.key}
          eidPrefix={eidPrefix} />,
        "name": name,
        "checked": (selectedIndex === index),
        "onChange": e => onChange(e, plan.callbackData),
        'data-eid': plan.eid,
        "value": index
      });
    });

    return (
      <div className="addon-card">
        <RadioGroup inputs={inputs} proxy />
      </div>
    );
  }

  return null;
};

RadioPlanGroup.propTypes = {
  selectedIndex: PropTypes.number,
  name: PropTypes.string.isRequired,
  plans: PropTypes.array,
  pricePerText: PropTypes.string,
  pricePerSecondaryText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  tooltipLinkText: PropTypes.string,
  market: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  eidPrefix: PropTypes.string
};

RadioPlanGroup.defaultProps = {
  selectedIndex: 0
};

export default RadioPlanGroup;
