export default ({
  match: (item) => {
    return item?.tracking?.itemTrackingCode === 'slp_wordpress';
  },
  rules: {
    design: '2',
    savings: 'PERCENT_OFF_SALE',
    durationUnit: 'MONTH',
    durationOptions: [1, 12, 24, 36],
    durationPricing: true
  }
});
