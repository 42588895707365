class JsonHelper {
  /**
   * Parses a string or an object to json
   *
   * @method parseJson
   * @param {string} json to be parsed can also be an object
   * @returns {object} Returns JSON object
   */
  static parseJson(json) {
    let jsonObject = null;
    if (typeof json === 'string') {
      try {
        jsonObject = JSON.parse(json);
      } catch (e) {
        jsonObject = null;
      }
    } else if (typeof json === 'object') {
      jsonObject = json;
    }

    return (jsonObject && typeof jsonObject === 'object') ? jsonObject : null;
  }
}

export default JsonHelper;
