import axios from 'axios';
import UrlHelper from '../utilities/UrlHelper';

const headers = {
  'Content-Type': 'application/x-www-form-urlencoded',
  'Accept': 'application/json'
};

/**
 * Gets domain search results.
 *
 * @param {JSON} params (addons, apiKey, excludePromo, searchTerm, numberOfSpins)
 * @param {string} urlOverride (dev-godaddy.com, test-godaddy.com)
 * @returns {object} promise
 */
export function getSearchResults(params, urlOverride) {
  const apiUrl = UrlHelper.getDomainSearchUrl(params, urlOverride);
  return axios({
    headers: headers,
    method: "GET",
    url: apiUrl,
    withCredentials: true
  }).then((response) => { return Promise.resolve(response); })
    .catch((response) => { return Promise.reject(response); });
}

/**
 * Gets domain search bundle results.
 *
 * @param {JSON} params (apiKey, searchTerm)
 * @param {string} urlOverride (dev-godaddy.com, test-godaddy.com)
 * @returns {object} promise
 */
export function getBundleResults(params, urlOverride) {
  const apiUrl = UrlHelper.getDomainBundleSearchUrl(params, urlOverride);
  return axios({
    headers: headers,
    method: "GET",
    url: apiUrl,
    withCredentials: true
  }).then((response) => { return Promise.resolve(response); })
    .catch((response) => { return Promise.reject(response); });
}

/**
 * Gets the data necessary to post to the Cart.
 *
 * @param {JSON} params (apiKey, fqdn, isPremium, itemTrackingCode, tierId, tld, skipAvailCheck)
 * @param {string} urlOverride (dev-godaddy.com, test-godaddy.com)
 * @returns {object} promise
 */
export function getCartPostData(params, urlOverride) {
  const requestData = {
    apiKey: params.apiKey,
    domain_name: params.fqdn,
    isavailcheck_needed: !params.skipAvailCheck,
    isPremium: params.isPremium,
    tier_id: params.tierId,
    tld: params.tld,
    tracking_code: params.itemTrackingCode,
    includePrivacy: params.includePrivacy
  };

  const apiUrl = UrlHelper.getCheckAndGetVCartPostDataUrl(urlOverride, params.plid);
  return axios({
    data: JSON.stringify(requestData),
    headers: headers,
    method: "POST",
    url: apiUrl,
    withCredentials: false
  }).then((response) => { return Promise.resolve(response); })
    .catch((response) => { return Promise.reject(response); });
}
