import dot from 'dot-object';
import { baseUrls } from './baseUrls';
import defaultConfig from './defaultConfig';

let config = {};

function init(initConfig = defaultConfig, host, env) {
  config = {};
  if (host && env && typeof initConfig === 'function') {
    config = initConfig(host)[env] || {};
  } else if (typeof window !== 'undefined' && window) {
    if (window.location && window.location.hostname) {
      const baseUrl = baseUrls.find(b => window.location.hostname.endsWith(b.host));
      if (baseUrl && baseUrl.host && baseUrl.env) {
        config = initConfig(baseUrl.host.substring(1))[baseUrl.env] || {};
      }
    }
  }
}

function get(key) {
  return dot.pick(key, config);
}

function add(newConfig) {
  if (typeof newConfig === 'object') {
    config = { ...config, ...newConfig };
  }
}

init();
export default { get, add, init };
