import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TrashIcon from '@ux/icon/trash';
import Button from '@ux/button';
import { Flex, Block, Card, Text } from '@px/react-utils';

function DesignBulk1(props) {
  const { bulkProducts, onChange } = props;
  const { viewAll, minimize } = props;
  const [open, setOpen] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  const handleChange = (update) => {
    setInProgress(true);
    onChange && onChange(update);
  };

  return (
    <React.Fragment>
      { bulkProducts && (
        <Card stack p={{ x: 'medium', y: 'xsmall' }}>
          <center>
            <Button
              design='tertiaryInline'
              data-eid={ `stx.checkout.product.bulk-${!open ? 'open' : 'close'}.click` }
              onClick={ () => setOpen(!open) }>{open ? minimize : viewAll}</Button>
          </center>
        </Card>
      )}
      { open && bulkProducts?.map(product => (
        <Card stack p='medium'>
          <Flex row justifyBetween alignCenter>
            <Text wrap className={ inProgress ? 'text-muted' : '' }>{product.label}</Text>
            <Block invisible={ !product.removable }>
              <Button
                design='tertiaryInline'
                size='small'
                data-eid='stx.checkout.product.bulk-delete.click'
                onClick={ () => handleChange({ deleteBulk: true, metadata: product.metadata }) }
                disabled={ inProgress }
              >
                <Flex>
                  <TrashIcon height={ 16 } width={ 16 } />
                </Flex>
              </Button>
            </Block>
          </Flex>
        </Card>
      )) }
    </React.Fragment>
  );
}

DesignBulk1.propTypes = {
  onChange: PropTypes.func,
  bulkProducts: PropTypes.array,
  viewAll: PropTypes.string,
  minimize: PropTypes.string
};

export default DesignBulk1;
