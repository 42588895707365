import SubDomainService from "../services/SubDomainService";

const hosts = {
  gd: "godaddy.com",
  reseller: "secureserver.net"
};

const prefixes = {
  fos: "https://",
  cart: "https://cart."
};

const paths = {
  domainSearch: "/domainsapi/v1/search",
  domainBundle: "/domainsapi/v1/bundles",
  availCheckAndAdd: "/domainsapi/v1/check/add",
  serpUrl: "/domains/searchresults.aspx",
  virtualCart: "/upp/vcart"
};


class UrlHelper {
  /**
   * Concatenates URL pieces together
   *
   * @method buildUrl
   * @param  {String} prefix Example: https://www.
   * @param  {String} host Example: godaddy.com
   * @param  {String} path Example: /domainsapi/v1/search
   * @returns {String} Returns URL
   */
  static buildUrl(prefix, host, path) {
    return prefix.concat(host, path);
  }

  /**
   * Concatenates URL pieces together
   *
   * @method getBuildUrlParameters
   * @param {String} hostOverride example: dev-godaddy.com
   * @param {Integer} plid plid for reseller
   * @returns {JSON} Returns prefix and host to build URL
   */
  static getBuildUrlParameters(hostOverride, plid) {
    var value = SubDomainService.getSubDomainAndLanguage();
    let prefix = prefixes.fos;

    if (!plid || plid === 1) {
      prefix = prefix.concat(value.subDomain, ".")
    } else {
      prefix = prefix.concat("www.")
    }
    let host = hostOverride;

    if (!host) {
      host = plid && plid > 1 && hosts.reseller || hosts.gd;
    }

    if (value.language) {
      host = host.concat("/", value.language);
    }
    return {
      prefix: prefix,
      host: host
    };
  }

  /**
   * Returns the fully qualified domainBaseUrl Url for correct environment
   *
   * @method getDomainSearchBaseUrl
   * @param  {String} hostOverride override for host. Example: dev-godaddy.com
   * @returns {String} Returns URL
   */
  static getDomainSearchBaseUrl(hostOverride) {
    const parameters = this.getBuildUrlParameters(hostOverride);
    return this.buildUrl(parameters.prefix, parameters.host, paths.domainSearch);
  }

  /**
   * Returns the fully qualified search results Url for correct environment
   *
   * @method getSerpUrl
   * @param  {String} domainName is the domain searched
   * @param  {Integer} plid plid for reseller
   * @param  {String} urlOverride override the default host
   * @returns {String} Returns URL
   */
  static getSerpUrl(domainName, plid, urlOverride) {
    const parameters = this.getBuildUrlParameters(urlOverride, plid);
    let buildSerpUrl = this.buildUrl(parameters.prefix, parameters.host, paths.serpUrl);
    buildSerpUrl = buildSerpUrl.concat("?domainToCheck=", domainName);

    if (plid && plid > 1) {
      buildSerpUrl = buildSerpUrl.concat("&plid=", plid)
    }

    return buildSerpUrl;
  }

  /**
   * Returns the fully qualified domain search All Url for correct environment
   *
   * @param  {JSON} params (addons, apiKey, excludePromo, searchTerm, numberOfSpins)
   * @param  {String} urlOverride override for host. Example: dev-godaddy.com
   * @returns {String} Returns URL
   */
  static getDomainSearchUrl(params, urlOverride) {
    const parameters = this.getBuildUrlParameters(urlOverride, params.plid);
    let url = this.buildUrl(parameters.prefix, parameters.host, paths.domainSearch);
    const searchParam = encodeURIComponent(params.searchTerm);
    url = url.concat(`/all?q=${searchParam}&pagestart=0&pagesize=${params.numberOfSpins || 4}&key=${params.apiKey}&countryview=1&credentailsneeded=true`);

    if (params.excludePromo) {
      url = url.concat("&excludepromo=", params.excludePromo);
    }
    if (params.addons && params.addons.length > 0) {
      url = url.concat("&addons=", params.addons.join(','));
    }

    if (params.plid && params.plid > 1) {
      url = url.concat("&plid=", params.plid)
    }

    return url;
  }

  /**
   * Returns the fully qualified domain bundle search Url for correct environment
   *
   * @param  {JSON} params (apiKey, searchTerm)
   * @param  {String} urlOverride override for host. Example: dev-godaddy.com
   * @returns {String} Returns URL
   */
  static getDomainBundleSearchUrl(params, urlOverride) {
    const parameters = this.getBuildUrlParameters(urlOverride, params.plid);
    let url = this.buildUrl(parameters.prefix, parameters.host, paths.domainBundle);
    url = url.concat(`/?q=${params.searchTerm}`);

    if (params.plid && params.plid > 1) {
      url = url.concat("&plid=", params.plid)
    }

    return url;
  }


  /**
   * Returns the fully qualified availcheck and 'add domain to VCart PostData' Url for correct environment
   *
   * @method getCheckAndGetVCartPostDataBaseUrl
   * @param  {String} hostOverride override for host. Example: dev-godaddy.com
   * @param  {Integer} plid plid for reseller
   * @returns {String} Returns URL
   */
  static getCheckAndGetVCartPostDataUrl(hostOverride, plid) {
    const parameters = this.getBuildUrlParameters(hostOverride, plid);
    let url = this.buildUrl(parameters.prefix, parameters.host, paths.availCheckAndAdd) + '?countryview=1';

    if (plid && plid > 1) {
      url = url.concat("&plid=", plid)
    }

    return url;
  }


  /**
   * Returns the fully qualified availcheck and 'add domain to VCart PostData' Url for correct environment
   *
   * @method getCheckAndGetVCartPostDataBaseUrl
   * @param  {String} hostOverride override for host. Example: dev-godaddy.com
   * @param  {Integer} plid plid for resellers
   * @returns {String} Returns URL
   */
  static getVirtualCartUrl(hostOverride, plid) {
    let host = hostOverride;

    if (!host) {
      host = plid && plid > 1 && hosts.reseller || hosts.gd;
    }

    let url = this.buildUrl(prefixes.cart, host, paths.virtualCart);

    if (plid && plid > 1) {
      url = url.concat("?plid=", plid)
    }

    return url;
  }
}

export default UrlHelper;
