import subdomain from './subdomain';
import { ENVIRONMENTS, SERVICES, SITES, KEYS } from './constants';


export default (host) => {
  const COMMON = {
    [SERVICES.ACCOUNT]: `https://account.${host}`,
    [SERVICES.AKAMAI_URL]: `https://img6.wsimg.com`,
    [SERVICES.AVAILCHECK]: `https://availcheck.api.${host}`,
    [SERVICES.CONTENT_API]: `https://content.${host}`,
    [SERVICES.CONSTRAINTS_SERVICE]: `https://constraints-service.cp.api.${host}`,
    [SERVICES.GATEWAY]: `https://gateway.api.${host}`,
    [SERVICES.GUI_SERVICE]: `https://gui.${host}/pcjson/salesheader`,
    [SERVICES.ORDERS_SERVICE]: `https://gateway.api.${host}`,
    [SERVICES.PAYMENT_API]: `https://payment.api.${host}`,
    [SERVICES.PLATFORM_API]: `https://api.${host}`,
    [SERVICES.QUERIES_SERVICE]: `https://gateway.api.${host}`,
    [SERVICES.RECEIPTS_SERVICE]: `https://gateway.api.${host}`,
    [SERVICES.SALES_PRODUCTS_API]: `https://salesproducts.api.${host}`,
    [SERVICES.SUBSCRIPTIONS_SERVICE]: `https://gateway.api.${host}`,
    [SERVICES.SUPPORT]: `https://support.api.${host}`,
    [SERVICES.TLD_API]: `https://tld.api.int.${host}`,
    [SERVICES.DOMAINS]: `https://ecomm.domains.api.${host}`,
    [SERVICES.FOS_API]: subdomain(host),
    [SITES.HOST]: host,
    [SITES.MY_ACCOUNT]: `https://account.${host}`,
    [SITES.CART]: `https://cart.${host}`,
    [SITES.HOME_PAGE]: `https://www.${host}`,
    [SITES.PAYMENT_FRAME]: `https://payment.api.${host}/static/js/nes/11`,
    [SITES.REFUND_URL]: `https://refunds.int.${host}`
  };

  // We spread COMMON first to allow overriding values by environment if needed
  return {
    [ENVIRONMENTS.DEV]: {
      ...COMMON,
      [SERVICES.AKAMAI_URL]: `https://img6.dev-wsimg.com`,
      [SERVICES.AVAILCHECK]: 'https://availcheck.api.dev.aws.godaddy.com',
      [SERVICES.CONSTRAINTS_SERVICE]: `https://constraints-service.cp.api.int.${host}`,
      [SERVICES.GATEWAY]: `https://gateway.api.int.${host}`,
      [SERVICES.ORDERS_SERVICE]: `https://gateway.api.int.${host}`,
      [SERVICES.QUERIES_SERVICE]: `https://gateway.api.int.${host}`,
      [SERVICES.RECEIPTS_SERVICE]: `https://gateway.api.int.${host}`,
      [SERVICES.SUBSCRIPTIONS_SERVICE]: `https://gateway.api.int.${host}`,
      [SITES.PAYMENT_FRAME]: `https://payment.api.${host}/cdn/px/payments/dev`,
      [KEYS.SPLIT_IO_BROWSER]: 'bb0psbuoiec4alb91ocfn491equtdnntjk1h'
    },
    [ENVIRONMENTS.TEST]: {
      ...COMMON,
      [SERVICES.AKAMAI_URL]: `https://img6.test-wsimg.com`,
      [SERVICES.AVAILCHECK]: 'https://availcheck.api.test.aws.godaddy.com',
      [SERVICES.CONSTRAINTS_SERVICE]: `https://constraints-service.cp.api.int.${host}`,
      [SERVICES.GATEWAY]: `https://gateway.api.int.${host}`,
      [SERVICES.ORDERS_SERVICE]: `https://gateway.api.int.${host}`,
      [SERVICES.QUERIES_SERVICE]: `https://gateway.api.int.${host}`,
      [SERVICES.RECEIPTS_SERVICE]: `https://gateway.api.int.${host}`,
      [SERVICES.SUBSCRIPTIONS_SERVICE]: `https://gateway.api.int.${host}`,
      [SITES.PAYMENT_FRAME]: `https://payment.api.${host}/cdn/px/payments/test`,
      [KEYS.SPLIT_IO_BROWSER]: 't8f7lfk1lma9hdqv6epemu0dl73d51gku55'
    },
    [ENVIRONMENTS.OTE]: {
      ...COMMON,
      [SERVICES.AVAILCHECK]: 'https://availcheck.api.ote.aws.godaddy.com'
    },
    [ENVIRONMENTS.PROD]: {
      ...COMMON,
      [SERVICES.AVAILCHECK]: 'https://availcheck.api.godaddy.com',
      [KEYS.SPLIT_IO_BROWSER]: 'd6qjli790mlqu5bmvhb8asd5nsv2an55dnt5'
    }
  };
};
