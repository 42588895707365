export default ({
  match: (item) => {
    return (
      item.productTypeId === 2
    );
  },
  rules: {
    design: '1',
    xsellDesign: 'Privacy',
    durationUnit: 'YEAR',
    priceDescription: 'MULTI_YEAR',
    savings: 'PERCENT_OFF'
  }
});
