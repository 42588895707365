import React from 'react';
import PropTypes from 'prop-types';
import TrashIcon from '@ux/icon/trash';
import Button from '@ux/button';
import DurationWithRadioButtons from './common/DurationWithRadioButtons';
import QuantityWithSelector from './common/Quantity';
import { Flex, Block, Card, Text } from '@px/react-utils';

function Design2(props) {
  const { label, description, price, renewalPrice, metadata } = props;
  const { duration, quantity, removable, onChange  } = props;

  const handleChange = (e) => {
    const update = { ...e };
    if (e.metadata || metadata) {
      update.metadata = { ...e.metadata, ...metadata };
    }
    onChange && onChange(update);
  };

  return (
    <React.Fragment>
      <Card stack p='medium'>
        <Flex column>
          <Text wrap bold>{label}</Text>
          <Text wrap small color='muted'>{description}</Text>
        </Flex>
        {(duration || price) &&
          <DurationWithRadioButtons
            { ...duration }
            quantityLabel={ quantity?.label }
            onChange={ handleChange }
          />
        }
        {quantity &&
          <Block m={{ y: 'small' }}>
            <QuantityWithSelector
              { ...quantity }
              onChange={ handleChange }
            />
          </Block>
        }
        {(renewalPrice || removable) &&
          <Flex justify='between' align='center'>
            <Text small color='muted' >{renewalPrice}</Text>
            <Block invisible={ !removable }>
              <Button
                design='tertiaryInline'
                size='small'
                data-eid='stx.checkout.product.delete.click'
                onClick={ () => handleChange({ delete: true }) }
              >
                <Flex>
                  <TrashIcon height={ 16 } width={ 16 } />
                </Flex>
              </Button>
            </Block>
          </Flex>
        }
      </Card>
    </React.Fragment>
  );
}

Design2.propTypes = {
  banner: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.string,
  body: PropTypes.string,
  price: PropTypes.string,
  priceDescription: PropTypes.array,
  renewalPrice: PropTypes.string,
  removable: PropTypes.bool,
  duration: PropTypes.object,
  quantity: PropTypes.object,
  onChange: PropTypes.func,
  metadata: PropTypes.object
};

export default Design2;
