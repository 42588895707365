import React, { useState } from 'react';
import PropTypes from 'prop-types';
import QuantitySelector from '@ux/quantity-selector';
import { Text } from '@px/react-utils';
import { css } from 'linaria';
import trfq from 'react-trfq';
import uniqueId from 'lodash.uniqueid';
import debounce from 'lodash.debounce';

const overrides = css`
  .ux-quantity-selector-text { min-width: 5rem; }
`;

function Quantity({ value, label, errorLabel, description, options, onChange }) {
  const [id] = useState(uniqueId('quantity-'));

  if (!value && !label) {
    return null;
  }

  if (label && (!options?.max || !options?.min)) {
    return label;
  }

  const handleChange = (newValue) => {
    if (newValue !== value && newValue <= options?.max && newValue >= options?.min) {
      trfq.logEvent('stx.checkout.product.quantity.update', 'update');
      onChange && onChange({ quantity: newValue });
    }
  };

  return (
    <div className={ overrides }>
      <QuantitySelector
        id={ id }
        defaultValue={ value }
        label={ <Text small>{description}</Text> }
        errorMessage={ errorLabel || '' }
        min={ options?.min }
        max={ options?.max }
        onChange={ debounce(handleChange, 500) }
      />
    </div>
  );
}

Quantity.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.number,
  label: PropTypes.string,
  errorLabel: PropTypes.string,
  description: PropTypes.string,
  options: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
    label: PropTypes.string
  })
};

export default Quantity;
