export default ({
  match: (item) => {
    return item.productTypeId === 4 && item?.metadata?.domains?.length > 1;
  },
  rules: {
    design: '1',
    bulkDesign: 'bulk',
    durationUnit: 'YEAR',
    priceDescription: 'MULTI_YEAR',
    savings: 'PERCENT_OFF'
  }
});
