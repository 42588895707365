import { ENVIRONMENTS } from './constants';

export const baseUrls = [
  { host: '.dev-godaddy.com', env: ENVIRONMENTS.DEV },
  { host: '.dev-secureserver.net', env: ENVIRONMENTS.DEV },
  { host: '.test-godaddy.com', env: ENVIRONMENTS.TEST },
  { host: '.test-secureserver.net', env: ENVIRONMENTS.TEST },
  { host: '.ote-godaddy.com', env: ENVIRONMENTS.OTE },
  { host: '.ote-secureserver.net', env: ENVIRONMENTS.OTE },
  { host: '.godaddy.com', env: ENVIRONMENTS.PROD },
  { host: '.secureserver.net', env: ENVIRONMENTS.PROD }
];
