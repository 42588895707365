

export const sizes = {
  xsmall: 1,
  small: 2,
  medium: 3,
  large: 4,
  xlarge: 5
};

export default (props = {}) => {
  const padding = props.padding  || props.p;
  const margin = props.margin  || props.m;

  return {
    /* padding */
    [`p-${sizes[padding]}`]: typeof padding === 'string',
    [`pt-${sizes[padding?.top]}`]: padding?.top,
    [`pr-${sizes[padding?.right]}`]: padding?.right,
    [`pb-${sizes[padding?.bottom]}`]: padding?.bottom,
    [`pl-${sizes[padding?.left]}`]: padding?.left,
    [`px-${sizes[padding?.x]}`]: padding?.x,
    [`py-${sizes[padding?.y]}`]: padding?.y,

    /* margin */
    [`m-${sizes[margin]}`]: typeof margin === 'string',
    [`mt-${sizes[margin?.top]}`]: margin?.top,
    [`mr-${sizes[margin?.right]}`]: margin?.right,
    [`mb-${sizes[margin?.bottom]}`]: margin?.bottom,
    [`ml-${sizes[margin?.left]}`]: margin?.left,
    [`mx-${sizes[margin?.x]}`]: margin?.x,
    [`my-${sizes[margin?.y]}`]: margin?.y
  };
};
