import React from 'react';
import PropTypes from 'prop-types';
import Button from '@ux/button';
import JsonHelper from '../utilities/JsonHelper';
import VirtualCartFormPost from '../vcart-post';
import { STATUS } from '../constants/DomainStatus';

export const BaseBuyButton = (props) => {
  const { buttonText, renewalText, premiumPriceDisplay, unavailableText, verifyingText, status, buyClick, isDisabled, listPriceDisplay, salePriceDisplay, hasSalePrice, redirectToVCart, buttonDesign, payload, readyForCart, eid } = props;

  let renderContent;
  if (status === STATUS.VERIFYING) {
    renderContent = (<h6 className="headline-primary">{verifyingText}</h6>);
  } else if (status === STATUS.UNAVAILABLE) {
    renderContent = (<h6 className="headline-primary unavailable">{unavailableText}</h6>);
  } else {
    let priceBlock = (
      <span className="dpp-price text-primary-o">
        {salePriceDisplay}
      </span>
    );

    if (hasSalePrice) {
      priceBlock = (
        <div className="price-block">
          {priceBlock}
          <small><s>{listPriceDisplay}</s></small>
        </div>
      );
    }

    // Price Display for Premium names
    if (premiumPriceDisplay) {
      priceBlock = (
        <div className="price-block">
          <span className="dpp-price text-primary-o">
            {premiumPriceDisplay}
            <span className="renewal-price">+ {listPriceDisplay}/yr</span>
          </span>
          <span className="renewal-text">{listPriceDisplay}{renewalText}</span>
        </div>
      );
    }

    renderContent = (
      <div>
        {priceBlock}
        <Button data-eid={`${eid}buy.click`} design={buttonDesign} className='btn-sm' onClick={buyClick} disabled={isDisabled}>
          {buttonText}
        </Button>
      </div>
    );
  }

  return (
    <div className='buy-btn-wrap'>
      {renderContent}
      {redirectToVCart && readyForCart && <VirtualCartFormPost {...props} payload={JsonHelper.parseJson(payload)} shouldSubmitForm />}
    </div>
  );
};

BaseBuyButton.propTypes = {
  readyForCart: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  payload: PropTypes.any.isRequired,
  buttonText: PropTypes.string.isRequired,
  eid: PropTypes.string.isRequired,
  renewalText: PropTypes.string,
  premiumPriceDisplay: PropTypes.string,
  unavailableText: PropTypes.string.isRequired,
  verifyingText: PropTypes.string.isRequired,
  redirectToVCart: PropTypes.bool,
  buttonDesign: PropTypes.string,
  isDisabled: PropTypes.bool,
  buyClick: PropTypes.func.isRequired,
  urlOverride: PropTypes.string,
  hasSalePrice: PropTypes.bool,
  listPriceDisplay: PropTypes.string,
  salePriceDisplay: PropTypes.string,
  plid: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

BaseBuyButton.defaultProps = {
  buttonDesign: 'primary',
  isDisabled: false,
  hasSalePrice: false
};

export default BaseBuyButton;
