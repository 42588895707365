import domainRegistration from './domains/domainRegistration';
import domainStack from './domains/domainStack';
import bulk from './domains/bulk';
import office365 from './email/office365';
import wordpress from './hosting/wordpress';
import fallback from './fallback';
import solutionSetStarter from './solution-sets/starterPlan';
import solutionSetBasic from './solution-sets/basicPlan';
import solutionSetEssentials from './solution-sets/essentialsPlan';
import solutionSetEcommerce from './solution-sets/ecommercePlan';
import wsbFreemium from './wsb/freemium';
import getTargetingRules from './targetingRules';
import { getCohorts } from '@px/experiment';
import domainPrivacy from './domains/domainPrivacy';
const list = [
  solutionSetStarter,
  solutionSetBasic,
  solutionSetEssentials,
  solutionSetEcommerce,
  domainStack,
  domainRegistration,
  domainPrivacy,
  bulk,
  office365,
  wordpress,
  wsbFreemium
];

export default async (item) => {
  const rules = list.find(({ match }) => match(item))?.rules || fallback(item);

  try {
    const attributes = getTargetingRules(item);
    const cohorts = await getCohorts({ attributes, labels: ['renderator'] });
    const experimentRules = Object.keys(cohorts).map(cohortId => cohorts[cohortId]?.data);
    return { ...rules, ...experimentRules[0] };
  } catch {
    return rules;
  }
};
