import React from 'react';
import PropTypes from 'prop-types';
import TrashIcon from '@ux/icon/trash';
import Button from '@ux/button';
import DurationWithDropdown from './common/DurationWithDropdown';
import Quantity from './common/Quantity';
import { Flex, Block, Card, Text } from '@px/react-utils';

function Design1(props) {
  const { label, description, description2, body, annotation, metadata } = props;
  const { duration, quantity, removable, onChange } = props;
  const { price, priceDescription, renewalPrice  } = props;
  const isEditable = duration?.options || quantity?.options || removable;

  const handleChange = (e) => {
    const update = { ...e };
    if (e.metadata || metadata) {
      update.metadata = { ...e.metadata, ...metadata };
    }
    onChange && onChange(update);
  };

  return (
    <React.Fragment>
      {annotation &&
        <Card stack bg='faint' p={{ x: 'medium', y: 'xsmall' }}>
          <Text small>{annotation}</Text>
        </Card>
      }
      <Card stack p='medium'>
        <Flex column>
          <Text wrap bold={ isEditable || price }>{label}</Text>
          {body &&
            <Block m={{ y: 'small' }}>
              <Text xsmall>{body}</Text>
            </Block>
          }
        </Flex>
        {(duration || price || description) &&
          <Flex wrap justify={ duration ? 'between' : 'end' } align={ duration?.options ? 'center' : 'top' }>
            <Flex grow={ 1 } minWidth='50%'>
              <Flex column>
                <Text wrap small color='muted'>{description}</Text>
                {description2 && <Text wrap small color='muted'>{description2}</Text>}
                {duration && <Block m={{ y: 'small' }}>
                  <DurationWithDropdown
                    { ...duration }
                    onChange={ handleChange }
                  />
                </Block>}
              </Flex>
            </Flex>
            <Flex grow={ 10 } m={{ left: 'small' }} column alignEnd justifyCenter={ duration }>
              <Text bold color='primary-o'>{price}</Text>
              {priceDescription && priceDescription?.map(text =>
                <Text key={ text } xsmall compact color='muted'>{text}</Text>
              )}
            </Flex>
          </Flex>
        }
        {quantity &&
          <Block m={{ y: 'small' }}>
            <Quantity
              { ...quantity }
              onChange={ handleChange }
            />
          </Block>
        }
        {(renewalPrice || removable) &&
          <Flex justifyBetween alignCenter>
            <Text small color='muted'>{renewalPrice}</Text>
            <Block invisible={ !removable }>
              <Button
                design='tertiaryInline'
                size='small'
                data-eid='stx.checkout.product.delete.click'
                onClick={ () => handleChange({ delete: true }) }
              >
                <Flex>
                  <TrashIcon height={ 16 } width={ 16 } />
                </Flex>
              </Button>
            </Block>
          </Flex>
        }
      </Card>
    </React.Fragment>
  );
}

Design1.propTypes = {
  annotation: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.string,
  description2: PropTypes.string,
  body: PropTypes.string,
  price: PropTypes.string,
  priceDescription: PropTypes.array,
  renewalPrice: PropTypes.string,
  removable: PropTypes.bool,
  duration: PropTypes.object,
  quantity: PropTypes.object,
  onChange: PropTypes.func,
  metadata: PropTypes.object
};

export default Design1;
