
export default (item, basket) => {
  for (let i = 0; i < basket?.items?.length; i++) {
    const basketItem = basket?.items?.[i];
    if (basketItem?.pfid === 1307404 && basketItem?.metadata?.domains?.[0].domain === item?.metadata?.domains?.[0].domain) {
      return basketItem;
    }
  }
  return null;
};
