export default ({
  match: (item) => {
    return item?.tracking?.itemTrackingCode === 'slp_365_email';
  },
  rules: {
    design: '2',
    savings: 'PERCENT_OFF_SALE',
    durationUnit: 'MONTH',
    durationOptions: [1, 12],
    durationPricing: true,
    quantityUnit: 'USER'
  }
});
