export default {
  Auction: "auction",
  Available: "available",
  Error: "error",
  None: "none",
  PreReg: "prereg",
  Restricted: "restricted",
  SearchInProgress: "searching",
  Taken: "taken",
  Unavailable: "unavailable",
  Search: "search",
  Bundles: "bundles",
  BundlesDone: "bundles-done",
  SearchDone: "search-done"
};
