import getDuration from './conversions/duration';
import getQuantity from './conversions/quantity';
import getLabel from './conversions/label';
import getDescription from './conversions/description';
import getPricing from './conversions/pricing';
import getAnnotation from './conversions/annotation';
import pickBy from 'lodash.pickby';
import getRules from './rules';

async function renderBasketGroup(group) {
  const [parentItem, ...childItems] = group.items;
  const rules = await getRules(parentItem);

  if (group.name) {
    childItems.unshift({ ...parentItem });
    parentItem.label = group.name;
  }

  return [
    renderParentItem(group, parentItem, rules),
    ...renderChildItems(group, childItems, rules)
  ];
}


// eslint-disable-next-line max-statements
function renderParentItem(group, item, rules) {
  if (rules.rollupPricing) {
    let savingsTotal = 0, listTotal = 0, subtotal = 0, saleTotal = 0, feeTotal = 0, icannTotal = 0;

    for (let i = 0; i < group.items.length; i++) {
      const groupItemPricing = group.items[i] && group.items[i].pricing;
      if (groupItemPricing) {
        const itemPercentOff = Math.floor((groupItemPricing.savings / groupItemPricing.list) * 100);

        if (itemPercentOff < 100) {
          savingsTotal += groupItemPricing.savings;
          listTotal += groupItemPricing.list;
          subtotal += groupItemPricing.subtotal;
          saleTotal += groupItemPricing.sale;
          feeTotal += groupItemPricing.fee && groupItemPricing.fee.total || 0;
          icannTotal += groupItemPricing.fee && groupItemPricing.fee.icann || 0;
        }
      }
    }

    item.pricing = {
      ...item.pricing,
      savings: savingsTotal,
      list: listTotal,
      subtotal: subtotal,
      sale: saleTotal,
      fee: {
        total: feeTotal,
        icann: icannTotal
      }
    };
  }

  const pricing = getPricing(item, rules);
  const render = {
    design: rules.design,
    label: getLabel(item, rules),
    description: getDescription(item, rules),
    quantity: getQuantity(item, rules),
    duration: getDuration(item, rules),
    removable: true,
    price: pricing.price,
    unitPrice: pricing.unitPrice,
    renewalPrice: pricing.renewalPrice,
    listPrice: pricing.listPrice,
    unitListPrice: pricing.unitPrice,
    metadata: {
      context: 'basket-group',
      itemId: item.itemId,
      groupKey: group.groupKey
    }
  };

  return pickBy(render, property => !!property);
}

function renderChildItems(group, items, rules) {

  return items.map((item, index) => {
    const pricing = getPricing(item, rules);
    const showPrice = item.pricing.subtotal === 0 && item.canRemoveAlone && !group.isHardBundle;
    const showRenewalPrice = !rules.rollupPricing && !group.isHardBundle;
    const annotation = getAnnotation(item, rules);
    const showAnnotation = index === 0 || getAnnotation(items[index - 1], rules) !== annotation;

    const render = {
      design: 1,
      annotation: showAnnotation && annotation,
      label: getLabel(item),
      price: showPrice && pricing.price,
      priceDescription: showPrice && pricing.priceDescription,
      renewalPrice: showRenewalPrice && pricing.renewalPrice,
      removable: item.canRemoveAlone,
      metadata: {
        context: 'basket-group',
        groupKey: group.groupKey,
        itemId: item.itemId
      }
    };

    return pickBy(render, property => !!property);
  });
}

export default renderBasketGroup;
