import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@ux/checkbox';


const CheckboxPlan = ({ name, productName, callbackData, eid, description, isSelected, onChange }) => {
  return (
    <div className="checkbox-details">
      <div key={name + 'check'}>
        <Checkbox label={<h3 id={name} className="font-primary-bold">{productName}</h3>} name={name}
          onChange={e => onChange(e, callbackData)}
          defaultChecked={isSelected} data-eid={eid} proxy />
        <div className="checkbox-description">
          {description}
        </div>
      </div>
    </div>
  );
};

CheckboxPlan.propTypes = {
  isSelected: PropTypes.bool,
  name: PropTypes.string.isRequired,
  productName: PropTypes.string,
  callbackData: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  eid: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default CheckboxPlan;
