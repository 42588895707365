import language from '../../../utils/language';
import getDuration from './duration';
import getQuantity from './quantity';
import getLabel from './label';
import getDescription from './description';
import getPricing from './pricing';
import getPrivacyItem from './privacyItem';

export default (item, basket, rules, showPrivacy) => {
  if (!basket || !showPrivacy) { return null; }

  const privacyItem = getPrivacyItem(item, basket);
  if (item?.crossSells?.length === 1 && !privacyItem) {
    const xsell = item?.crossSells[0];
    const price = getPricing(xsell, rules);
    return {
      design: rules.xsellDesign,
      buttonText: language('PRIVACY_CROSSELL_BUTTON_TEXT'),
      description: language('PRIVACY_CROSSELL_DESCRIPTION'),
      label: language('PRIVACY_CROSSELL_LABEL'),
      toolTipText: language('PRIVACY_CROSSELL_TOOLTIP'),
      priceDescription: [language('PRIVACY_CROSSELL_UNIT_YEAR', { price: price.price })],
      metadata: {
        domain: item?.metadata?.domains[0].domain,
        periodCount: item.period,
        pfid: xsell.pfid,
        context: 'basket-item',
        tracking: xsell.tracking
      }
    };
  } else if (privacyItem) {
    return {
      design: rules.design,
      label: getLabel(privacyItem, rules),
      description: getDescription(privacyItem, rules),
      ...getPricing(privacyItem, rules),
      quantity: getQuantity(privacyItem, rules),
      duration: getDuration(privacyItem, rules),
      removable: true,
      metadata: {
        context: 'basket-item',
        itemId: privacyItem.itemId
      }
    };
  }
  return null;
};
