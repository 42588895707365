import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BaseBuyButton from './BaseBuyButton';
import { STATUS } from '../constants/DomainStatus';
import { getCartPostData } from '../services/DomainServices';
import { isDomainPremium } from '../utilities/domain';

export default class BuyButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: STATUS.DEFAULT,
      payload: {},
      readyForCart: false
    };

    this.buyClick = this.buyClick.bind(this);
    this.getPostData = this.getPostData.bind(this);
  }

  componentDidUpdate(prevProps) {
    // domain has changed in this spot, button should be default again
    if (prevProps.domain && prevProps.domain.Fqdn !== this.props.domain.Fqdn) {
      this.setState({
        status: STATUS.DEFAULT
      });
    }
  }

  buyClick() {
    this.setState({
      status: STATUS.VERIFYING
    });
    this.getPostData();
  }

  getPostData() {
    const {
      domain,
      apiKey,
      trackingCode,
      urlOverride,
      plid,
      skipAvailCheck,
      includePrivacy,
      failureCallback,
      successCallback,
      unavailableCallback
    } = this.props;

    const params = {
      apiKey: apiKey,
      fqdn: domain.Fqdn,
      isPremium: isDomainPremium(domain),
      itemTrackingCode: trackingCode || "",
      tierId: domain.TierId,
      tld: domain.Extension,
      skipAvailCheck: skipAvailCheck,
      includePrivacy,
      plid
    };

    getCartPostData(params, urlOverride)
      .then((response) => {
        if (response.status === 204) {
          this.setState({
            readyForCart: false,
            status: STATUS.UNAVAILABLE,
            payload: {}
          });
          unavailableCallback && unavailableCallback(domain);
        } else {
          this.setState({
            payload: response.data,
            readyForCart: true
          });
          successCallback && successCallback(response, [domain.Fqdn], domain);
        }
      })
      .catch((error) => {
        this.setState({
          status: STATUS.ERROR
        });
        failureCallback && failureCallback(error);
      });
  }

  render() {
    const { buttonText, eid, renewalText, hasSalePrice, id, listPriceDisplay, salePriceDisplay, premiumPriceDisplay, unavailableText, verifyingText, redirectToVCart, buttonDesign, isDisabled, urlOverride, plid, vcartNavigation, domain } = this.props;
    const { readyForCart, status, payload } = this.state;

    return (
      <BaseBuyButton
        readyForCart={ readyForCart }
        status={ status }
        payload={ payload }
        id={ id }
        buyClick={ this.buyClick }
        removeClick={ this.removeClick }
        buttonText={ buttonText }
        eid={ eid }
        plid={ plid }
        renewalText={ renewalText }
        hasSalePrice={ hasSalePrice }
        listPriceDisplay={ listPriceDisplay }
        salePriceDisplay={ salePriceDisplay }
        premiumPriceDisplay={ premiumPriceDisplay }
        unavailableText={ unavailableText }
        verifyingText={ verifyingText }
        redirectToVCart={ redirectToVCart }
        buttonDesign={ buttonDesign }
        isDisabled={ isDisabled }
        urlOverride={ urlOverride }
        vcartNavigation={ vcartNavigation }
        domain={ domain }
      />
    );
  }
}

BuyButton.propTypes = {
  apiKey: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  buttonDesign: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  domain: PropTypes.object.isRequired,
  eid: PropTypes.string.isRequired,
  failureCallback: PropTypes.func,
  hasSalePrice: PropTypes.bool,
  isc: PropTypes.string,
  isDisabled: PropTypes.bool,
  listPriceDisplay: PropTypes.string,
  plid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  premiumPriceDisplay: PropTypes.string,
  redirectToVCart: PropTypes.bool,
  removeCallback: PropTypes.func,
  renewalText: PropTypes.string,
  salePriceDisplay: PropTypes.string,
  skipAvailCheck: PropTypes.bool,
  successCallback: PropTypes.func,
  unavailableCallback: PropTypes.func,
  trackingCode: PropTypes.string,
  unavailableText: PropTypes.string.isRequired,
  urlOverride: PropTypes.string,
  vcartNavigation: PropTypes.object,
  vcartOptions: PropTypes.object,
  verifyingText: PropTypes.string.isRequired,
  includePrivacy: PropTypes.bool
};

BuyButton.defaultProps = {
  buttonDesign: 'primary',
  isDisabled: false
};
