
const packageIds = [
  'dpp-us-solution-tier2',
  'dpp-intl-solution-tier2'
];

export default ({
  match: (item) => {
    return packageIds.includes(item.packageId);
  },
  rules: {
    design: '1',
    label: 'BASIC_PLAN',
    description: 'DOMAIN_NAME',
    savings: 'PERCENT_OFF',
    durationUnit: 'YEAR',
    rollupPricing: true,
    annotate: true
  }
});
