export default ({
  match: (item) => {
    return item.productTypeId === 23 && item.pfid === 1307404;
  },
  rules: {
    design: 'null',
    durationUnit: 'YEAR',
    priceDescription: 'MULTI_YEAR',
    savings: 'PERCENT_OFF'
  }
});
