import IntlMessageFormat from 'intl-messageformat';
import i18n from '../../i18n';
import { cookie } from '@ux/util';

export default (id, values, fallback) => {
  try {
    const market = cookie.get('market');
    const messages = i18n(market);
    return new IntlMessageFormat(messages[id], market).format(values);
  } catch {
    return fallback || id;
  }
};
