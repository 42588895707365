import { cookie } from '@ux/util';

function getDeviceType() {
  const matchMedia = window.matchMedia("screen and (max-width: 767px)");
  return matchMedia && matchMedia.matches ? "mobile" : "desktop";
}

function getBrowser() {
  let browser = 'unknown';
  const isIE = /*@cc_on!@*/false || !!document.documentMode; // eslint-disable-line spaced-comment
  const isEdge = !isIE && !!window.StyleMedia;

  if (navigator.userAgent.includes("Chrome") && !isEdge) {
    browser = 'chrome';
  } else if (navigator.userAgent.includes("Safari") && !isEdge) {
    browser = 'safari';
  } else if (navigator.userAgent.includes("Firefox")) {
    browser = 'firefox';
  } else if (navigator.userAgent.includes("MSIE") || document.documentMode) {
    browser = 'ie';
  } else if (isEdge) {
    browser = 'edge';
  }

  return browser;
}

export default {
  browser: getBrowser(),
  market: cookie.getItem('market') || "unknown",
  deviceType: getDeviceType()
}