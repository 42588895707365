import createStyled from '../createStyled';
import spacing from '../styles/spacing';
import border from '../styles/border';
import visibility from '../styles/visibility';
import background from '../styles/background';
import sizing from '../styles/sizing';

const Block = createStyled('div', 'Block')(
  spacing,
  sizing,
  background,
  visibility,
  border
);

export default Block;
